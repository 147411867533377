import React, { useState, useEffect } from "react";
import { mobile, emailValidator } from "../../validation/validator";
import { APP_URL, token } from "../../validation/basic";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
const BasicinfoFrm = ({ nextStep, user, setUser, edituser, setEdituser }) => {
  const [password, setPassword] = useState(false);
  const [isvalid, setIsvalid] = useState(false);
  const [nameerror, setNameerror] = useState(true);
  const [emailerror, setEmailerror] = useState(true);
  const [mobileerror, setMobileerror] = useState(true);
  const [departmenterror, setDepartmenterror] = useState(false);
  const [departmentlist, setDepartmentlist] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [ischecked, setIschecked] = useState(true);
  const [ismasked, setIsmasked] = useState(true);
  const [isallow, setIsallow] = useState(true);
  const [listusers, setListusers] = useState([]);

  const [token, setToken] = useState(localStorage.getItem("token"));
  const {
    company_id,
    department_id,
    name,
    mobile,
    email,
    is_password,
    usepassword,
    cnfpassword,
    employee_type,
    roll_id,
    menu,
    permission,
    is_masking,
    is_allow,
  } = user;
  const settingpassword = (e) => {
    setIschecked(false);
    var ps = e.target.value;
    if (ps == 1) {
      setPassword(false);
      setIschecked(true);
    } else {
      setPassword(true);
    }
    setUser({ ...user, is_password: e.target.value });
  };

  const settingmasking = (e) => {
    setIsmasked(false);
    const ps1 = e.target.value;
    if (ps1 == 1) {
      setIsmasked(true);
    }
    setUser({ ...user, is_masking: e.target.value });
  };

  const settingallow = (e) => {
    setIsallow(false);
    const ps1 = e.target.value;
    if (ps1 == 1) {
      setIsallow(true);
    }

    setUser({ ...user, is_allow: e.target.value });
  };

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadDepartment();
    loadCompanies();
    // user_filter();
  }, []);

  const loadDepartment = async () => {
    const department_details = await axios.get(APP_URL + "departments", {
      headers: { Authorization: `Bearer ${token}` },
    });
    var js_data_department = JSON.parse(
      JSON.stringify(department_details.data)
    );
    if (js_data_department.status == 1) {
      setDepartmentlist(js_data_department.data.departments);
    }
  };

  const loadCompanies = async () => {
    const department_details = await axios.get(APP_URL + "companies", {
      headers: { Authorization: `Bearer ${token}` },
    });
    var js_data_department = JSON.parse(
      JSON.stringify(department_details.data)
    );
    if (js_data_department.status == 1) {
      setCompanies(js_data_department.data.companies);
    }
  };

  const user_filter = async () => {
    try {
      const user_lists = await axios.get(APP_URL + "users", {
        headers: { Authorization: `Bearer ${token}` },
      });
      let user_lists_data = JSON.parse(JSON.stringify(user_lists.data));
      if (user_lists_data.status == 1) {
        setListusers(user_lists_data.data.user_list);
      }
    } catch (e) {
      return false;
    }
  };

  const handlechange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const validatefrm = () => {
    if (department_id == 0) {
      setDepartmenterror(true);
    } else {
      setDepartmenterror(false);
    }
    if (name == null || name == "") {
      setNameerror(false);
    }

    if (mobile == null || name == "") {
      setMobileerror(false);
    }

    if (email == null || email == "") {
      setEmailerror(false);
    }

    if (name == "" || email == "" || mobile == "") {
      return false;
    } else {
      return true;
    }
  };

  const BtnClick = (e) => {
    e.preventDefault();
    if (validatefrm() == true) {
      nextStep();
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="row pt-3">
        <h1>{Response}</h1>

        <div className="col-lg-6">
          <div className="row mb-2">
            <div className="col-md-3">
              <label>
                Company{" "}
                <sup style={{ color: "red" }}>
                  <i className="fa fa-asterisk" style={{ fontSize: "8px" }}></i>
                </sup>
              </label>
            </div>
            <div className="col-md-9 form-group">
              <select
                className={
                  "form-control " + (departmenterror == true ? "invalid" : "")
                }
                name="company_id"
                placeholder="Name"
                onChange={(e) => handlechange(e)}
              >
                <option value="0">Select company</option>
                {companies.map((item, index) => (
                  <>
                    {company_id == item.id ? (
                      <option value={item.id} selected>
                        {item.company_name}
                      </option>
                    ) : (
                      <option value={item.id}>{item.company_name}</option>
                    )}
                  </>
                ))}
              </select>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-3">
              <label>
                Department{" "}
                <sup style={{ color: "red" }}>
                  <i className="fa fa-asterisk" style={{ fontSize: "8px" }}></i>
                </sup>
              </label>
            </div>
            <div className="col-md-9 form-group">
              <select
                className={
                  "form-control " + (departmenterror == true ? "invalid" : "")
                }
                name="department_id"
                placeholder="Name"
                onChange={(e) => handlechange(e)}
              >
                <option value="0">Select department</option>
                {departmentlist.map((item, index) => (
                  <>
                    {department_id == item.id ? (
                      <option value={item.id} selected>
                        {item.department_name}
                      </option>
                    ) : (
                      <option value={item.id}>{item.department_name}</option>
                    )}
                  </>
                ))}
              </select>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-3">
              <label>
                Name{" "}
                <sup style={{ color: "red" }}>
                  <i className="fa fa-asterisk" style={{ fontSize: "8px" }}></i>
                </sup>
              </label>
            </div>
            <div className="col-md-9 form-group">
              <input
                type="text"
                id="name"
                className={
                  "form-control " + (nameerror == false ? "invalid" : "")
                }
                name="name"
                placeholder="Name"
                onChange={(e) => handlechange(e)}
                value={name}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-3">
              <label>
                Mobile{" "}
                <sup style={{ color: "red" }}>
                  <i className="fa fa-asterisk" style={{ fontSize: "8px" }}></i>
                </sup>
              </label>
            </div>
            <div className="col-md-9 form-group">
              <input
                type="text"
                id="mobile"
                className={
                  "form-control " + (mobileerror == false ? "invalid" : "")
                }
                name="mobile"
                maxLength={10}
                placeholder="Mobile"
                onChange={(e) => handlechange(e)}
                value={mobile}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-3">
              <label>
                Email{" "}
                <sup style={{ color: "red" }}>
                  <i className="fa fa-asterisk" style={{ fontSize: "8px" }}></i>
                </sup>
              </label>
            </div>
            <div className="col-md-9 form-group">
              <input
                type="text"
                id="email"
                className={
                  "form-control " + (emailerror == false ? "invalid" : "")
                }
                name="email"
                placeholder="email"
                onChange={(e) => handlechange(e)}
                value={email}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="col-md-12">
            <p>
              <strong>Set Password</strong>
            </p>
            <label>
              <input
                type="radio"
                name="is_password"
                value="1"
                onClick={settingpassword}
                checked={ischecked}
              />{" "}
              <span className="checkedLabel">No</span>
              <input
                type="radio"
                name="is_password"
                value="2"
                onClick={settingpassword}
              />{" "}
              <span className="checkedLabel">Yes</span>
            </label>
          </div>
          <div
            className="setPasswordfield"
            style={{ display: password ? "block" : "none" }}
          >
            <div class="row">
              <div className="col-md-4">
                <label>
                  Password{" "}
                  <sup style={{ color: "red" }}>
                    <i
                      className="fa fa-asterisk"
                      style={{ fontSize: "8px" }}
                    ></i>
                  </sup>
                </label>
              </div>
              <div className="col-md-8 form-group">
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  name="usepassword"
                  placeholder="Password"
                  onChange={(e) => handlechange(e)}
                  value={usepassword}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <label>
                  Re-Enter Password{" "}
                  <sup style={{ color: "red" }}>
                    <i
                      className="fa fa-asterisk"
                      style={{ fontSize: "8px" }}
                    ></i>
                  </sup>
                </label>
              </div>
              <div className="col-md-8 form-group">
                <input
                  type="password"
                  id="cnfpassword"
                  className="form-control"
                  name="cnfpassword"
                  placeholder="Password"
                  onChange={(e) => handlechange(e)}
                  value={cnfpassword}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-2">
            <p>
              <strong>Number masking</strong>
            </p>
            <label>
              <input
                type="radio"
                name="is_masking"
                value="0"
                onClick={settingmasking}
              />{" "}
              <span className="checkedLabel">No</span>
              <input
                type="radio"
                name="is_masking"
                value="1"
                onClick={settingmasking}
                checked={ismasked}
              />{" "}
              <span className="checkedLabel">Yes</span>
            </label>
          </div>

          <div className="col-md-12 mt-2">
            <p>
              <strong>Allow Access</strong>
            </p>
            <label>
              <input
                type="radio"
                name="is_allow"
                value="0"
                onClick={settingallow}
              />{" "}
              <span className="checkedLabel">All access</span>
              <input
                type="radio"
                name="is_allow"
                value="1"
                onClick={settingallow}
                checked={isallow}
              />{" "}
              <span className="checkedLabel">Specific access</span>
            </label>
          </div>
        </div>

        <div className="col-12 d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-primary me-1 mb-1"
            onClick={(e) => BtnClick(e)}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default BasicinfoFrm;
