import React from "react";
import { Link } from "react-router-dom";
import Topbar from "../../layout/topbar";
import FinalForm from "./finalFrm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const adduser = () => {
  return (
    <div id="app">
      <div id="main" className="layout-horizontal">
        <Topbar />
        <ToastContainer />
        <div className="content-wrapper container">
          <div className="page-heading"></div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <h5>Add New User</h5>
                    <div className="mt-4">
                      <FinalForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default adduser;
