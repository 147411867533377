import React, { useState, useEffect } from "react";
import { APP_URL, token } from "../../validation/basic";
import axios from "axios";
import Paginationcustom from "../../validation/pagination";
import { CSVLink } from "react-csv";
import Loader from "../../loader/loader";
import TableSkeleton from "../../loader/TableSkeleton";
const Message = ({
  sms,
  loading,
  permissionlist,
  accesspermission,
  smsData,
  smslist,
  exportMsgData,
  exportSMSLogDetails,
}) => {
  const [currentPage, setCurrentPage] = useState(
    smsData != null ? smsData.page : 1
  );
  // const [recordsPerPage] = useState(smsData!=null?smsData.limit:null);
  /*--pagination start here---*/

  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = sms;

  const nPages = smsData != null ? smsData.total_page_count : null;
  //const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const changepage = (e, p) => {
    setCurrentPage(p);
    smslist(parseInt(p));
  };

  /*--pagination start end here---*/

  return (
    <>
      {permissionlist &&
        permissionlist.map((p, ind) => (
          <>
            {accesspermission.some((item) => p.id == item) == true ? (
              <>
                {p.permission == "Export" ? (
                  <button
                    className="btn btn-success mb-2"
                    onClick={exportSMSLogDetails}
                  >
                    <i className="fa fa-download"></i> Export
                  </button>
                ) : null}
              </>
            ) : null}
          </>
        ))}

      <div className="table-responsive pb-5">
        <table className="table table-bordered table-hover mb-0">
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>Employee Code</th>
              <th>Sent no</th>
              <th>Received No</th>
              <th style={{ width: "250px" }}>Message</th>
              <th>type</th>
              <th>date</th>
            </tr>
          </thead>
          <tbody>
            {loading == true ? (
              <>
                {/* <tr>
                  <td colSpan="10">
                    <Loader />
                  </td>
                </tr> */}
				<TableSkeleton count={6} />
              </>
            ) : (
              <>
                {currentRecords?.length > 0 ?
                  currentRecords?.map((item, index) => (
                    <tr>
                      <td>{item.user.name}</td>
                      <td>{item.user.user_unique_code}</td>
                      <td>{item.send_from}</td>
                      <td>{item.received_from}</td>
                      <td style={{ width: "250px" }}>{item.description}</td>
                      <td>
                        {item.type == 1 ? (
                          <span className="badge bg-light-warning">
                            incoming
                          </span>
                        ) : item.type == 2 ? (
                          <span className="badge bg-light-success">
                            Outgoing
                          </span>
                        ) : null}
                      </td>
                      <td>{item.date}</td>
                    </tr>
                  ))
				  : 
				  (
					<tr>
					  <td colSpan="11">
						<div
						  style={{
							textAlign: "center",
							fontSize: "18px",
							fontWeight: "bold",
						  }}
						>
						  No Data Found
						</div>
					  </td>
					</tr>
				  )
				}
				  

              </>
            )}
          </tbody>
        </table>
      </div>

      <div style={{ textAlign: "right", float: "right" }}>
        <Paginationcustom
          data={nPages}
          changepage={changepage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default Message;
