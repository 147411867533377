import React from "react";
import { Link } from "react-router-dom";
import Footer from "../layout/footer";
import Master from "../layout/master";
import Topbar from "../layout/topbar";

const employee = () => {
  return (
    <div id="app">
      <div id="main" className="layout-horizontal">
        <Topbar />
        <div className="content-wrapper container">
          <div className="page-content">
            <section className="row">
              <div className="col-12 col-lg-12">
                <section className="section">
                  <div className="row" id="table-hover-row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="mb-3">Employee Details</h4>
                          <div className="buttons">
                            <Link to="/master/employee/add-employee">
                              <button className="btn btn-success rounded-pill">                                
                                <i className="iconly-boldPlus"></i> Add Employee
                              </button>
                            </Link>
                            <button className="btn btn-warning rounded-pill">                              
                              <i className="iconly-boldDownload"></i> Export
                            </button>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive pb-5">
                            <table className="table table-bordered table-hover mb-0">
                              <thead>
                                <tr>
                                  <th>slno</th>
                                  <th>Employee ID</th>
                                  <th>Employee Info</th>
                                  <th>Status</th>
                                  <th>ACTION</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-bold-500">1</td>
                                  <th>EMP000134</th>
                                  <td>Test</td>
                                  <td>
                                    <span className="badge bg-light-success">
                                      Activate
                                    </span>
                                  </td>
                                  <td>
                                    <div className="buttons">
                                      <button class="btn btn-success rounded-pill">
                                        <i className="fa fa-pencil"></i>
                                      </button>
                                      <button class="btn btn-danger rounded-pill">
                                        <i className="fa fa-trash"></i>
                                      </button>
                                      <button class="btn btn-secondary rounded-pill">
                                        <i className="fa fa-eye"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default employee;
