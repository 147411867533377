import ReactDOM, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../../layout/topbar";
import Footer from "../../layout/footer";
import axios from "axios";
import { APP_URL, getdates } from "../../validation/basic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import Paginationcustom from "../../validation/pagination";
import Select from "react-select";
import Loader from "../../loader/loader";
import TableSkeleton from "../../loader/TableSkeleton";
const Neverattended = () => {
  const [fromdate, setFromdate] = useState(getdates(0));
  const [todate, setTodate] = useState(getdates(0));
  const [employee, setEmployee] = useState("");
  const [employeelist, setEmployeelist] = useState([]);
  const [calltype, setCalltype] = useState("");
  const [neverattended, setNeverattended] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [selectedemp, setSelectedemp] = useState("");
  const [loading, setLoading] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [selectedDept, setSelectedDept] = useState("");
  const [depts, setDepts] = useState("");
  const [neverData, setNeverData] = useState(null);
  const [neverExportData, setNeverExportData] = useState([]);
  /*--pagination start here---*/

  const [currentPage, setCurrentPage] = useState(
    neverData != null ? neverData.page : 1
  );
  // const [recordsPerPage] = useState(neverData!=null?neverData.limit:null);
  /*--pagination start here---*/

  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = neverattended;

  const nPages = neverData != null ? neverData.total_page_count : null;
  //const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const changepage = (e, p) => {
    setCurrentPage(p);
    neverattendelist(parseInt(p), 5);
  };

  /*--pagination start end here---*/

  const neverattendelist = async (page, limit) => {
    try {
      setLoading(true);
      const attendedlist = await axios.get(
        APP_URL +
          "never-attended-report?employee_id=" +
          `${employee}` +
          "&from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&dept=" +
          `${depts}` +
          "&page=" +
          `${page}` +
          "&limit=" +
          `${limit}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let neverattended_data = JSON.parse(JSON.stringify(attendedlist.data));
      if (neverattended_data.status == 1) {
        setNeverattended(neverattended_data.data.getPeriodic);
        setNeverData(neverattended_data.data);
        setLoading(false);
      }
    } catch (e) {
      toast.error("something went wrong");
      return false;
    }
  };

  const exportNeverAttendedReport = async () => {
    /*--------------export never attended report-----------*/
    try {
      const attendedexportlist = await axios.get(
        APP_URL +
          "never-attended-report-export?employee_id=" +
          `${employee}` +
          "&from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&dept=" +
          `${depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const url = window.URL.createObjectURL(
        new Blob([attendedexportlist.data])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "neverattended-report.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      return false;
    }
  };

  const handlefromdatechange = (e) => {
    setFromdate(e.target.value);
  };

  const handletodatechange = (e) => {
    setTodate(e.target.value);
  };
  const handledepartment = (e) => {
    setSelectedDept(e);
    setDepts(e.value);
    loadusers(e.value);
  };

  const handleemployeechange = (e) => {
    setSelectedemp(e);
    setEmployee(e.value);
  };
  const handlecallchange = (e) => {
    setCalltype(e.target.value);
  };

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadusers(depts);
    neverattendelist(1, 5);
    loadDepartment();
  }, []);

  const loadusers = async (Depts) => {
    try {
      const users = await axios.get(
        APP_URL + "user-filter-crm?department=" + `${Depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      setEmployeelist(users_list_data.data.user_list);
    } catch (e) {
      return null;
    }
  };

  /*----------load department------------*/

  const loadDepartment = async () => {
    const department_details = await axios.get(APP_URL + "departments", {
      headers: { Authorization: `Bearer ${token}` },
    });
    var js_data_department = JSON.parse(
      JSON.stringify(department_details.data)
    );
    if (js_data_department.status == 1) {
      setDepartments(js_data_department.data.departments);
    }
  };

  const employeedata = [];
  employeelist.forEach((item) => {
    employeedata.push({
      value: item.id,
      label: item.name + "-" + item.user_unique_code,
    });
  });

  const departmentData = [];
  departments.forEach((item) => {
    departmentData.push({ value: item.id, label: item.department_name });
  });
  return (
    <>
      <div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar />
          <ToastContainer />
          <div className="content-wrapper container">
            <div className="page-content">
              <section className="row">
                <div className="col-12 col-lg-12">
                  <section className="section">
                    <div className="row" id="table-hover-row">
                      <div className="col-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <h5>
                              Never Attended Report (
                              {neverData && neverData.total_data})
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="">
                                  <table className="table table table-borderless mb-0">
                                    <thead>
                                      <tr>
                                        <th>From date</th>
                                        <th>To date</th>
                                        <th>Select Department</th>
                                        <th>Select Employee</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <input
                                            type="date"
                                            className="form-control"
                                            onChange={(e) =>
                                              handlefromdatechange(e)
                                            }
                                            value={fromdate}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="date"
                                            className="form-control"
                                            onChange={(e) =>
                                              handletodatechange(e)
                                            }
                                            value={todate}
                                          />
                                        </td>
                                        <td style={{ width: "250px" }}>
                                          <Select
                                            value={selectedDept}
                                            onChange={(e) =>
                                              handledepartment(e)
                                            }
                                            options={departmentData}
                                          />
                                        </td>
                                        <td style={{ width: "250px" }}>
                                          <Select
                                            value={selectedemp}
                                            onChange={(e) =>
                                              handleemployeechange(e)
                                            }
                                            options={employeedata}
                                          />
                                        </td>

                                        <td>
                                          <button
                                            className="btn btn-outline-success"
                                            onClick={() =>
                                              neverattendelist(1, 5)
                                            }
                                          >
                                            Apply
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-md-12">
                                <div className="col-sm-12">
                                  <h6>Reports</h6>
                                </div>

                                <button
                                  className="btn btn-success"
                                  onClick={exportNeverAttendedReport}
                                >
                                  <i className="fa fa-download"></i> Export
                                </button>
                                <div className="table-responsive pb-5">
                                  <table className="table table-bordered">
                                    {loading == true ? (
                                      <>
                                        {/* <Loader /> */}
                                        <TableSkeleton count={5}/>
                                      </>
                                    ) : (
                                      <>
                                        {currentRecords?.map((item, index) => (
                                            <>
                                              <thead>
                                                <tr className="custom_bg">
                                                  <th colSpan="6">
                                                    Employee - (
                                                    {item.agent_name}-
                                                    {item.agent_code})
                                                  </th>
                                                </tr>
                                                <tr>
                                                  <th>Agent no</th>
                                                  <th>Client no</th>
                                                  <th>Call Type</th>
                                                  <th>Date</th>
                                                  <th>Time</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {item.call_details.map(
                                                  (a, v) => (
                                                    <tr>
                                                      <td>
                                                        {item.agent_contact_no}
                                                      </td>
                                                      <td>
                                                        {a.client_update_contact_no}
                                                      </td>
                                                      <td>
                                                        {a.status == 3 ? (
                                                          <span
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            Missed Call
                                                          </span>
                                                        ) : null}
                                                      </td>
                                                      <td>{a.call_date}</td>
                                                      <td>{a.call_time}</td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </>
                                          ))}
                                      </>
                                    )}
                                  </table>
                                </div>
                                <div
                                  style={{ textAlign: "right", float: "right" }}
                                >
                                  <Paginationcustom
                                    data={nPages}
                                    changepage={changepage}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Neverattended;
