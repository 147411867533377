import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../layout/footer";
import Master from "../layout/master";
import Topbar from "../layout/topbar";
import { APP_URL } from "../validation/basic";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Department = () => {
  const [departmentslist, setDepartmentslist] = useState([]);
  const [rowsdata, setRowsdata] = useState([]);
  const [isvalid, setIsvalid] = useState(false);
  const [error, setError] = useState(false);
  const [listcompanies, setListcompanies] = useState([]);
  const [company_id, setCompany_id] = useState(0);
  const [department, setDepartment] = useState([]);
  const [permissionlist, setPermissionlist] = useState([]);
  const [accesspermission, setAccesspermission] = useState([]);
  const [menuids, setMenuids] = useState("");
  const [isedit, setIsedit] = useState(false);
  const [inphidden, setInphidden] = useState("");
  const [department_name, setDepartment_name] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadDepartment();
    loadCompany();
  }, []);

  const addrows = (e) => {
    e.preventDefault();
    let item = {
      deptname: "",
    };
    setRowsdata([...rowsdata, item]);
  };

  const removerows = (index) => () => {
    let rows = [...rowsdata];
    rows.splice(index, 1);
    setRowsdata([...rows]);
  };

  const loadCompany = async () => {
    try {
      const company_list = await axios.get(APP_URL + "companies", {
        headers: { Authorization: `Bearer ${token}` },
      });
      let companiesList = JSON.parse(JSON.stringify(company_list.data));
      setListcompanies(companiesList.data.companies);
    } catch (e) {
      return null;
    }
  };

  const loadDepartment = async () => {
    const department_details = await axios.get(APP_URL + "departments", {
      headers: { Authorization: `Bearer ${token}` },
    });
    var js_data_department = JSON.parse(
      JSON.stringify(department_details.data)
    );
    if (js_data_department.status == 1) {
      setDepartmentslist(js_data_department.data.departments);
    }
  };

  const delete_department = async (index) => {
    var id = index;
    if (!window.confirm("Are you sure want to delete this")) {
      return false;
    } else {
      try {
        const delete_department_details = await axios.delete(
          APP_URL + "departments" + `${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        var js_data_delete_department = JSON.parse(
          JSON.stringify(delete_department_details.data)
        );
        if (js_data_delete_department.status == 1) {
          toast.success("department deleted successfull");
          loadDepartment();
        } else {
          toast.error("something went wrong");
        }
      } catch (e) {
        toast.error("something went wrong");
      }
    }
  };

  const change_status_department = async (index) => {
    var id = index;
    if (!window.confirm("Are you sure want to change this")) {
      return false;
    } else {
      try {
        const activate_department = await axios.get(
          APP_URL + "departments/status/" + `${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        var js_data_change_status = JSON.parse(
          JSON.stringify(activate_department.data)
        );
        if (js_data_change_status.status == 1) {
          toast.success("status has been changed");
          loadDepartment();
        } else {
          toast.error("something went wrong");
        }
      } catch (e) {
        toast.error("something went wrong");
      }
    }
  };

  const selectcompany = (e) => {
    setCompany_id(e.target.value);
  };
  const changedepartment = (i, e) => {
    setDepartment({ ...department, [e.target.name]: e.target.value });
  };
  const adddepartment = async (e) => {
    e.preventDefault();
    try {
      const department_list = department;
      const update_department = Object.values(department_list);
      var setdata = {
        company_id,
        update_department,
      };

      const adddepartment = await axios.post(APP_URL + "departments", setdata, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let departmentdetails = JSON.parse(JSON.stringify(adddepartment.data));
      if (departmentdetails.status == 1) {
        toast.success("department created successfull");
        loadDepartment();
      } else {
        toast.success("something went wrong");
      }
    } catch (e) {
      toast.error("something went wrong");
    }
  };

  const edit_dept_details = async (id) => {
    try {
      setIsedit(true);
      const dept_details = await axios.get(APP_URL + "departments/" + `${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const department_data = JSON.parse(JSON.stringify(dept_details.data));
      if (department_data.status == 1) {
        setCompany_id(department_data.data.department.company_id);
        setDepartment_name(department_data.data.department.department_name);
        setInphidden(department_data.data.department.id);
      }
    } catch (e) {
      return false;
    }
  };

  const editchangedept = (e) => {
    setDepartment_name(e.target.value);
  };

  const update_department_details = async () => {
    try {
      let form1 = new URLSearchParams();
      form1.append("company_id", company_id);
      form1.append("department_name", department_name);
      const update_data = await axios.put(
        APP_URL + "departments/" + `${inphidden}`,
        form1,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const updated_data = JSON.parse(JSON.stringify(update_data.data));
      if (updated_data.status == 1) {
        loadDepartment();
        toast.success("department updated successfull");
        setIsedit(false);
      } else {
        toast.error("something went wrong");
      }
    } catch (e) {
      toast.error("something went wrong");
    }
  };

  /*-----------copy dept id-------------*/

  const copyuserid = async (index) => {
    let id = index;
    var TempText = document.createElement("input");
    TempText.value = id;
    document.body.appendChild(TempText);
    TempText.select();
    document.execCommand("copy");
    toast.success("copied successfull");
    document.body.removeChild(TempText);
  };

  return (
    <div id="app">
      <div id="main" className="layout-horizontal">
        <Topbar
          setMenuids={setMenuids}
          menuids={menuids}
          setAccesspermission={setAccesspermission}
          accesspermission={accesspermission}
          setPermissionlist={setPermissionlist}
          permissionlist={permissionlist}
        />
        <ToastContainer />
        <div className="content-wrapper container">
          <div className="page-heading">
            <h4>Department Details</h4>
          </div>
          <div className="page-content">
            <section className="row">
              <div className="col-12 col-lg-5">
                <section id="basic-vertical-layouts">
                  <div className="row match-height">
                    <div className="col-md-12 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-body">
                            <div className="form-body">
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label for="first-name-vertical">
                                      Select Company
                                    </label>
                                    <select
                                      type="text"
                                      id="first-name-vertical"
                                      className={
                                        "form-control " +
                                        (error ? "invalid" : "")
                                      }
                                      name="select_cmp"
                                      onChange={(e) => selectcompany(e)}
                                    >
                                      <option value="0">Select company</option>
                                      {listcompanies.map((item, index) => (
                                        <>
                                          {company_id != "" ? (company_id == item.id ? (
                                              <option value={item.id} selected>
                                                {item.company_name}
                                              </option>
                                            ) : (
                                              <option value={item.id}>
                                                {item.company_name}
                                              </option>
                                            )
                                          ) : (
                                            <option value={item.id}>
                                              {item.company_name}
                                            </option>
                                          )}
                                        </>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                {isedit == false ? (
                                  <>
                                    <div className="col-12">
                                      <div className="row">
                                        <label for="first-name-vertical">
                                          Department Name
                                        </label>
                                        <div className="form-group col-md-10">
                                          <input
                                            type="text"
                                            id="first-name-vertical"
                                            className={"form-control " + (error ? "invalid" : "")}
                                            name="deptname0"
                                            placeholder="Department Name"
                                            onChange={(e) =>
                                              changedepartment(0, e)
                                            }
                                          />
                                        </div>

                                        <div className="col-md-2">
                                          <button
                                            className="btn btn-success"
                                            onClick={addrows}
                                          >
                                            <i class="iconly-boldPlus"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    {rowsdata?.map((item, index) => {
                                      return (
                                        <div className="col-12">
                                          <div className="row">
                                            <label for="first-name-vertical">
                                              Department Name
                                            </label>
                                            <div className="form-group col-md-10">
                                              <input
                                                type="text"
                                                id="first-name-vertical"
                                                className={"form-control " + (error ? "invalid" : "")}
                                                name={"deptname" + parseInt(index + 1) }
                                                placeholder="Department Name"
                                                onChange={(e) =>
                                                  changedepartment(index + 1, e)
                                                }
                                              />
                                            </div>

                                            <div className="col-md-2">
                                              <button
                                                className="btn btn-danger"
                                                data={index}
                                                onClick={removerows(index)}
                                              >
                                                <i class="fa fa-minus"></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>
                                    <div className="col-12">
                                      <div className="row">
                                        <label for="first-name-vertical">
                                          Department Name
                                        </label>
                                        <div className="form-group col-md-12">
                                          <input
                                            type="text"
                                            id="first-name-vertical"
                                            className={"form-control " + (error ? "invalid" : "")}
                                            name="deptname"
                                            placeholder="Department Name"
                                            onChange={(e) => editchangedept(e)}
                                            value={department_name}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                <div className="col-12 d-flex justify-content-end">
                                  {isedit == false ? (
                                    <button
                                      className="btn btn-primary me-1 mb-1"
                                      onClick={(e) => adddepartment(e)}
                                    >
                                      Submit
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-primary me-1 mb-1"
                                      onClick={update_department_details}
                                    >
                                      Update
                                    </button>
                                  )}
                                  <button
                                    type="reset"
                                    className="btn btn-light-secondary me-1 mb-1"
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-12 col-lg-7">
                <section className="section">
                  <div className="row" id="table-hover-row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive pb-5">
                            <table className="table table-bordered table-hover mb-0">
                              <thead>
                                <tr>
                                  <th>Department</th>
                                  <th>Company</th>
                                  <th>Status</th>
                                  <th>ACTION</th>
                                </tr>
                              </thead>
                              <tbody>
                                {departmentslist?.map((item, index) => (
                                  <tr>
                                    <td className="text-bold-500">
                                      {item.department_name}
                                    </td>
                                    <td>
                                      {item.company == null ? null : item?.company?.company_name}
                                    </td>
                                    <td>
                                      {item.status == 1 ? (
                                        <span
                                          className="badge bg-light-success"
                                          onClick={(e) =>
                                            change_status_department(item.id)
                                          }
                                        >
                                          Activate
                                        </span>
                                      ) : (
                                        <span
                                          className="badge bg-light-danger"
                                          onClick={(e) =>
                                            change_status_department(item.id)
                                          }
                                        >
                                          Deactivate
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <div className="buttons">
                                        {permissionlist &&
                                          permissionlist?.map((p, ind) => (
                                            <>
                                              {accesspermission?.some((item) => p.id == item) == true ? (
                                                <>
                                                  {p.permission == "Edit" ? (
                                                    <button
                                                      class="btn btn-success rounded-pill btn-custom"
                                                      onClick={() => edit_dept_details(item.id)}
                                                    >
                                                      <i className="fa fa-pencil"></i>
                                                    </button>
                                                  ) : null}
                                                  {p.permission == "Delete" ? (
                                                    <button
                                                      class="btn btn-danger rounded-pill btn-custom"
                                                      onClick={(e) => delete_department(item.id)}
                                                    >
                                                      <i className="fa fa-trash"></i>
                                                    </button>
                                                  ) : null}
                                                  {p.permission == "Copy" ? (
                                                    <button
                                                      className="btn btn-warning rounded-pill btn-custom"
                                                      onClick={(e) =>
                                                        copyuserid(
                                                          item.encrypt_department_id
                                                        )
                                                      }
                                                      title="copy user id"
                                                    >
                                                      <i className="fa fa-copy"></i>
                                                    </button>
                                                  ) : null}
                                                </>
                                              ) : null}
                                            </>
                                          ))}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Department;
