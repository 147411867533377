import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import Topbar from "../../layout/topbar";
import Footer from "../../layout/footer";
import { APP_URL, getdates } from "../../validation/basic";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-apexcharts";
import Player from "./Player";
import Call from "./call";
import Message from "./message";
import Contact from "./contact";
import Select from "react-select";
const Callrecording = () => {
  let { type } = useParams();
  let { user } = useParams();
  const { search } = useLocation();
  let type1 = new URLSearchParams(search).get("type");
  let user1 = new URLSearchParams(search).get("user");
  let callstatus = new URLSearchParams(search).get("callstatus");
  let liststatus = new URLSearchParams(search).get("listtype");
  let departmentSearch = new URLSearchParams(search).get("department");
  let from_date_url = new URLSearchParams(search).get("from_date");
  const [sms, setSms] = useState([]);
  const [contact, setContact] = useState([]);
  const [calls, setCalls] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [fromdate, setFromdate] = useState(from_date_url ? from_date_url : getdates(0));
  const [todate, setTodate] = useState(getdates(0));
  const [clientno, setClientno] = useState("");
  const [calltype, setCalltype] = useState(type1 == null ? "" : type1);
  const [rcavailable, setRcavailable] = useState("");
  const [selectemployee, setSelectemployee] = useState(
    user1 == null ? "" : user1
  );
  const [statustype, setStatustype] = useState(
    callstatus != null ? callstatus : ""
  );
  const [listtype, setListtype] = useState(liststatus == 2 ? 2 : 1);
  const [totalstorage, setTotalstorage] = useState(0);
  const [usedstorage, setUsedstorage] = useState(0);
  const [totalStorageUsed, setTotalStorageUsed] = useState(0);
  const [totalStorageUsedtext, setTotalStorageUsedtext] = useState(0);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [selectedemp, setSelectedemp] = useState("");
  const [selectedDept, setSelectedDept] = useState("");
  const [depts, setDepts] = useState(
    departmentSearch != null ? departmentSearch : ""
  );
  const [permissionlist, setPermissionlist] = useState([]);
  const [accesspermission, setAccesspermission] = useState([]);
  const [menuids, setMenuids] = useState("");
  const [departments, setDepartments] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [smsPageNo, setSmsPageNo] = useState(1);
  const [contactPageNo, setContactPageNo] = useState(1);
  const [calllogData, setCalllogData] = useState(null);
  const [smsData, setSmsData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [exportCallData, setExportCallData] = useState([]);
  const [exportMsgData, setExportMsgData] = useState([]);
  const [exportContactData, setExportContactData] = useState([]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Get the current URL and hash
      const url = new URL(window.location.href);
      let [basePath, queryString] = url.hash.split("?");
      
      if (queryString) {
        // Use URLSearchParams to manipulate query parameters in the hash
        let params = new URLSearchParams(queryString);
  
        // Remove specific query parameters
        params.delete("user");
        params.delete("department");
        params.delete("from_date");
  
        // Rebuild the hash
        let newHash = `${basePath}${params.toString() ? "?" + params.toString() : ""}`;
  
        // Update the URL without reloading the page
        window.history.replaceState(null, null, `${url.pathname}${newHash}`);
      }
    }, 1500);
  
    // Clean up the timeout when the component unmounts or when `from_date_url` changes
    return () => clearTimeout(timeoutId);
  }, [from_date_url]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadusers(depts);
    if (listtype == 1) {
      filterBy(pageNo);
    } else if (listtype == 2) {
      smslist(smsPageNo);
    } else if (listtype == 3) {
      contactlist(contactPageNo);
    }
    total_used();
    loadDepartment();
  }, []);

  const total_used = async () => {
    try {
      const recording_size = await axios.get(APP_URL + "storage-size", {
        headers: { Authorization: `Bearer ${token}` },
      });
      let recording_data = JSON.parse(JSON.stringify(recording_size.data));
      if (recording_data.status == 1) {
        setTotalstorage(recording_data.data.total_storage_size);
        setUsedstorage(recording_data.data.used_file_size);
        let storageSize =
          parseFloat(recording_data.data.used_file_size.replace(",", "")) /
          parseFloat(recording_data.data.total_storage_size);
        setTotalStorageUsed(Math.round(storageSize * 100));
        setTotalStorageUsedtext(
          parseFloat(
            (parseFloat(recording_data.data.used_file_size) /
              parseFloat(recording_data.data.total_storage_size)) *
              100
          )
        );
      }
    } catch (e) {
      return false;
    }
  };

  const loadrecording = async () => {
    const recording_files = await axios.get(APP_URL + "get-call-log-list", {
      headers: { Authorization: `Bearer ${token}` },
    });
    let recording = JSON.parse(JSON.stringify(recording_files.data));
    if (recording.status == 1) {
      setCalls(recording.data.call_log);
    }
  };

  const loadusers = async (Depts) => {
    try {
      const users = await axios.get(
        APP_URL + "user-filter-crm?department=" + `${Depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      setEmployee(users_list_data.data.user_list);
    } catch (e) {
      return null;
    }
  };

  const downloadfile = (url) => {
    if (!window.confirm("Are you sure want to download")) {
      return false;
    } else {
      // Creating new object of PDF file
      const fileURL = url;
      var a = document.createElement("a");
      // Setting various property values
      a.download = "recording.3gp";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  const delete_recording = async () => {
    if (!window.confirm("Are you sure want to delete")) {
      return false;
    } else {
      return true;
    }
  };

  const smslist = async (page) => {
    try {
      setLoading(true);
      const smslist = await axios.get(
        APP_URL +
          "get-data-list?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&clientno=" +
          `${clientno}` +
          "&user_id=" +
          `${selectemployee}` +
          "&department=" +
          `${depts}` +
          "&page=" +
          `${page}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let smsdata = JSON.parse(JSON.stringify(smslist.data));
      if (smsdata.status == 1) {
        setSms(smsdata.data.DataList);
        setSmsData(smsdata.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      return false;
    }
  };

  const contactlist = async (page) => {
    try {
      setLoading(true);
      const contactlist = await axios.get(
        APP_URL +
          "contacts?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&clientno=" +
          `${clientno}` +
          "&user_id=" +
          `${selectemployee}` +
          "&department=" +
          `${depts}` +
          "&page=" +
          `${page}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let contactdata = JSON.parse(JSON.stringify(contactlist.data));
      if (contactdata.status == 1) {
        setContact(contactdata.data.ContactHistorys);
        setContactData(contactdata.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      return false;
    }
  };

  const filterBy = async (page) => {
    if (listtype == 2) {
      smslist(1);
    } else if (listtype == 3) {
      contactlist(1);
    } else {
      try {
        setLoading(true);
        const filterd = await axios.get(
          APP_URL +
            "get-call-log-list?from_date=" +
            `${fromdate}` +
            "&to_date=" +
            `${todate}` +
            "&client_contact_no=" +
            `${clientno}` +
            "&user_id=" +
            `${selectemployee}` +
            "&status=" +
            `${calltype}` +
            "&available=" +
            `${rcavailable}` +
            "&statustype=" +
            `${statustype}` +
            "&department=" +
            `${depts}` +
            "&page=" +
            `${page}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        let filtered_data = JSON.parse(JSON.stringify(filterd.data));
        if (filtered_data.status == 1) {
          setCalls(filtered_data.data.call_log);
          setCalllogData(filtered_data.data);
          setLoading(false);
        }
      } catch (e) {
        toast.error("something went wrong");
        return false;
      }
    }
  };

  const exportCallLogsDetails = async () => {
    /*--------export call log report---------*/
    try {
      const filterdexport = await axios.get(
        APP_URL +
          "get-call-log-report-export?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&client_contact_no=" +
          `${clientno}` +
          "&user_id=" +
          `${selectemployee}` +
          "&status=" +
          `${calltype}` +
          "&available=" +
          `${rcavailable}` +
          "&statustype=" +
          `${statustype}` +
          "&department=" +
          `${depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const url = window.URL.createObjectURL(new Blob([filterdexport.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "call-log-export.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      return false;
    }
  };

  const exportSMSLogDetails = async () => {
    /*----------export sms------*/
    try {
      const smsexport = await axios.get(
        APP_URL +
          "get-message-report-export?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&clientno=" +
          `${clientno}` +
          "&user_id=" +
          `${selectemployee}` +
          "&department=" +
          `${depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const url = window.URL.createObjectURL(new Blob([smsexport.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "message-report-export.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      return false;
    }
  };

  const exportContactLogDetails = async () => {
    /*-----------contact export----------*/
    try {
      const contactexportlist = await axios.get(
        APP_URL +
          "contacts-report-export?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&clientno=" +
          `${clientno}` +
          "&user_id=" +
          `${selectemployee}` +
          "&department=" +
          `${depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const url = window.URL.createObjectURL(
        new Blob([contactexportlist.data])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "contact-report.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      return false;
    }
  };

  /*----------load department------------*/

  const loadDepartment = async () => {
    const department_details = await axios.get(APP_URL + "departments", {
      headers: { Authorization: `Bearer ${token}` },
    });
    var js_data_department = JSON.parse(
      JSON.stringify(department_details.data)
    );
    if (js_data_department.status == 1) {
      setDepartments(js_data_department.data.departments);
    }
  };

  /*----load department data----*/

  const departmentData = [];
  departments.forEach((item) => {
    departmentData.push({ value: item.id, label: item.department_name });
  });

  /*----load employee data--------*/

  const employeedata = [];
  employee.forEach((item) => {
    employeedata.push({
      value: item.id,
      label: item.name + "-" + item.user_unique_code,
    });
  });

  const handlefromdate = (e) => {
    setFromdate(e.target.value);
  };

  const handletodate = (e) => {
    setTodate(e.target.value);
  };

  const handleclientno = (e) => {
    setClientno(e.target.value);
  };

  const handleemployechange = (e) => {
    setSelectedemp(e);
    setSelectemployee(e.value);
  };

  const handledepartment = (e) => {
    setSelectedDept(e);
    setDepts(e.value);
    loadusers(e.value);
  };

  const changelisttype = (e) => {
    if (e.target.dataset.id == 1) {
      filterBy(pageNo);
    } else if (e.target.dataset.id == 2) {
      smslist(smsPageNo);
    } else if (e.target.dataset.id == 3) {
      contactlist(contactPageNo);
    }
    setListtype(e.target.dataset.id);
  };

  const handlecalltype = (e) => {
    setCalltype(e.target.value);
  };

  const handleavailable = (e) => {
    setRcavailable(e.target.value);
  };

  //console.log(totalStorageUsed);

  return (
    <>
      <div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar
            setMenuids={setMenuids}
            menuids={menuids}
            setAccesspermission={setAccesspermission}
            accesspermission={accesspermission}
            setPermissionlist={setPermissionlist}
            permissionlist={permissionlist}
          />
          <ToastContainer />
          <div className="content-wrapper container">
            <div className="page-content">
              <section className="row">
                <div className="col-12 col-lg-12">
                  <section className="section">
                    <div className="row" id="table-hover-row">
                      <div className="col-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <h5>
                              Call Recordings{" "}
                              <span>
                                ({calllogData && calllogData.total_data})
                              </span>{" "}
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-3 text-center">
                                <span>
                                  <strong>
                                    Storage Usage ({usedstorage} MB)
                                  </strong>
                                </span>
                                <div
                                  className="chartdesk"
                                  style={{ margin: "22px 0px 0px 0px" }}
                                >
                                  <Chart
                                    options={{
                                      chart: {
                                        type: "radialBar",
                                        offsetY: -20,
                                        sparkline: {
                                          enabled: true,
                                        },
                                      },
                                      plotOptions: {
                                        radialBar: {
                                          startAngle: -90,
                                          endAngle: 90,
                                          track: {
                                            background: "#e7e7e7",
                                            strokeWidth: "97%",
                                            margin: 5, // margin is in pixels
                                            dropShadow: {
                                              enabled: true,
                                              top: 2,
                                              left: 0,
                                              color: "#999",
                                              opacity: 1,
                                              blur: 2,
                                            },
                                          },
                                          dataLabels: {
                                            name: {
                                              show: false,
                                            },
                                            value: {
                                              offsetY: -2,
                                              fontSize: "22px",
                                            },
                                          },
                                        },
                                      },
                                      grid: {
                                        padding: {
                                          top: -10,
                                        },
                                      },
                                      fill: {
                                        type: "gradient",
                                        gradient: {
                                          shade: "light",
                                          shadeIntensity: 0.4,
                                          inverseColors: false,
                                          opacityFrom: 1,
                                          opacityTo: 1,
                                          stops: [0, 50, 53, 91],
                                        },
                                      },
                                      labels: ["used storage"],
                                    }}
                                    series={[totalStorageUsed]}
                                    type="radialBar"
                                    width="100%"
                                  />
                                </div>
                              </div>
                              <div className="col-md-9">
                                <div className="row">
                                  <div className="col-md-3 mb-2">
                                    <label>Select Department</label>
                                    <div className="mt-2">
                                      <Select
                                        value={departmentData?.filter(
                                          (option) => option.value == depts
                                        )}
                                        onChange={(e) => handledepartment(e)}
                                        options={departmentData}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 mb-2">
                                    <label>Employee Name</label>
                                    <div className="mt-2">
                                      <Select
                                        value={employeedata?.filter(
                                          (option) =>
                                            option.value == selectemployee
                                        )}
                                        onChange={(e) => handleemployechange(e)}
                                        options={employeedata}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 mb-2">
                                    <label>Client No</label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Client No."
                                        onChange={(e) => handleclientno(e)}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-3 mb-2">
                                    <label>Start Date</label>
                                    <div className="mt-2">
                                      <input
                                        type="date"
                                        className="form-control"
                                        onChange={(e) => handlefromdate(e)}
                                        value={fromdate}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-3 mb-2">
                                    <label>End Date</label>
                                    <div className="mt-2">
                                      <input
                                        type="date"
                                        className="form-control"
                                        onChange={(e) => handletodate(e)}
                                        value={todate}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-3 mb-2">
                                    <label>Select call</label>
                                    <div className="mt-2">
                                      <select
                                        className="form-control"
                                        onChange={(e) => handlecalltype(e)}
                                      >
                                        <option value="">Select call</option>
                                        {calltype == 1 ? (
                                          <>
                                            <option value="1" selected>
                                              Incoming
                                            </option>
                                            <option value="2">Outgoing</option>
                                            <option value="3">Missed</option>
                                          </>
                                        ) : calltype == 2 ? (
                                          <>
                                            <option value="1">Incoming</option>
                                            <option value="2" selected>
                                              Outgoing
                                            </option>
                                            <option value="3">Missed</option>
                                          </>
                                        ) : calltype == 3 ? (
                                          <>
                                            <option value="1">Incoming</option>
                                            <option value="2">Outgoing</option>
                                            <option value="3" selected>
                                              Missed
                                            </option>
                                          </>
                                        ) : (
                                          <>
                                            <option value="1">Incoming</option>
                                            <option value="2">Outgoing</option>
                                            <option value="3">Missed</option>
                                          </>
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-3 mb-2">
                                    <label>Select recording</label>
                                    <div className="mt-2">
                                      <select
                                        className="form-control"
                                        onChange={(e) => handleavailable(e)}
                                      >
                                        <option value="">
                                          Select recording
                                        </option>
                                        <option value="1">Available</option>
                                        <option value="0">Not Available</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-3 mb-2">
                                    <label></label>
                                    <div className="mt-2">
                                      <button
                                        className="btn btn-outline-success"
                                        onClick={() => filterBy(pageNo)}
                                      >
                                        Apply
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-md-12">
                                <h6>Employee Call Details</h6>

                                <div
                                  className="col-md-12"
                                  style={{ textAlign: "right" }}
                                >
                                  <div className="buttons">
                                    {permissionlist &&
                                      permissionlist.map((p, ind) => (
                                        <>
                                          {accesspermission.some((item) => p.id == item) == true ? (
                                            <>
                                              {p.permission == "Call" ? (
                                                <button
                                                  className={
                                                    "btn btn-outline-success " +
                                                    (listtype == 1
                                                      ? "active"
                                                      : null)
                                                  }
                                                  data-id="1"
                                                  onClick={(e) =>
                                                    changelisttype(e)
                                                  }
                                                >
                                                  <i className="fa fa-phone"></i>{" "}
                                                  Call
                                                </button>
                                              ) : null}
                                              {p.permission ==
                                              "View Message" ? (
                                                <button
                                                  className={
                                                    "btn btn-outline-primary " +
                                                    (listtype == 2
                                                      ? "active"
                                                      : null)
                                                  }
                                                  data-id="2"
                                                  onClick={(e) =>
                                                    changelisttype(e)
                                                  }
                                                >
                                                  <i className="fa fa-comment-o"></i>{" "}
                                                  Message
                                                </button>
                                              ) : null}
                                              {p.permission == "Contact" ? (
                                                <button
                                                  className={
                                                    "btn btn-outline-warning " +
                                                    (listtype == 3
                                                      ? "active"
                                                      : null)
                                                  }
                                                  data-id="3"
                                                  onClick={(e) =>
                                                    changelisttype(e)
                                                  }
                                                >
                                                  <i className="fa fa-address-book-o "></i>{" "}
                                                  Contact Details
                                                </button>
                                              ) : null}
                                            </>
                                          ) : null}
                                        </>
                                      ))}
                                  </div>
                                </div>

                                {listtype == 1 ? (
                                  <Call
                                    calls={calls}
                                    downloadfile={downloadfile}
                                    Player={Player}
                                    delete_recording={delete_recording}
                                    setLoading={setLoading}
                                    loading={loading}
                                    permissionlist={permissionlist}
                                    accesspermission={accesspermission}
                                    pageNo={pageNo}
                                    setPageNo={setPageNo}
                                    filterBy={filterBy}
                                    calllogData={calllogData}
                                    exportCallData={exportCallData}
                                    exportCallLogsDetails={
                                      exportCallLogsDetails
                                    }
                                  />
                                ) : listtype == 2 ? (
                                  <Message
                                    sms={sms}
                                    setLoading={setLoading}
                                    loading={loading}
                                    permissionlist={permissionlist}
                                    accesspermission={accesspermission}
                                    smsData={smsData}
                                    smslist={smslist}
                                    exportMsgData={exportMsgData}
                                    exportSMSLogDetails={exportSMSLogDetails}
                                  />
                                ) : (
                                  <Contact
                                    contact={contact}
                                    setLoading={setLoading}
                                    loading={loading}
                                    permissionlist={permissionlist}
                                    accesspermission={accesspermission}
                                    contactData={contactData}
                                    contactlist={contactlist}
                                    exportContactData={exportContactData}
                                    exportContactLogDetails={
                                      exportContactLogDetails
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Callrecording;
