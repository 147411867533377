import ReactDOM, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../../layout/topbar";
import Footer from "../../layout/footer";
import axios from "axios";
import { APP_URL, getdates } from "../../validation/basic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import Paginationcustom from "../../validation/pagination";
import Select from "react-select";
import Loader from "../../loader/loader";
import TableSkeleton from "../../loader/TableSkeleton";
const Clientreports = () => {
  const [fromdate, setFromdate] = useState(getdates(0));
  const [todate, setTodate] = useState(getdates(0));
  const [employee, setEmployee] = useState("");
  const [calltype, setCalltype] = useState("");
  const [uniqueclients, setUniqueclients] = useState([]);
  const [employeelist, setEmployeelist] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [selectedemp, setSelectedemp] = useState("");
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [selectedDept, setSelectedDept] = useState("");
  const [depts, setDepts] = useState("");
  const [clientData, setClientData] = useState(null);
  const [clientExportData, setClientExportData] = useState([]);

  /*--pagination start here---*/

  const [currentPage, setCurrentPage] = useState(
    clientData != null ? clientData.page : 1
  );
  // const [recordsPerPage] = useState(clientData!=null?clientData.limit:null);
  /*--pagination start here---*/

  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = uniqueclients;

  const nPages = clientData != null ? clientData.total_page_count : null;
  //const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const changepage = (e, p) => {
    setCurrentPage(p);
    clientlist(parseInt(p));
  };

  /*--pagination start end here---*/

  const clientlist = async (page) => {
    try {
      setLoading(true);
      const attendedlist = await axios.get(
        APP_URL +
          "get-unique-client-report?status=" +
          `${calltype}` +
          "&user_id=" +
          `${employee}` +
          "&from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&dept=" +
          `${depts}` +
          "&page=" +
          `${page}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let neverattended_data = JSON.parse(JSON.stringify(attendedlist.data));
      if (neverattended_data.status == 1) {
        setUniqueclients(neverattended_data.data.calllogs);
        setClientData(neverattended_data.data);
        setLoading(false);
      }
    } catch (e) {
      toast.error("something went wrong");
      return false;
    }
  };

  const exportUniqueClientReport = async () => {
    /*--------export client details---------*/
    try {
      const attendedexportlist = await axios.get(
        APP_URL +
          "get-unique-client-report-export?status=" +
          `${calltype}` +
          "&user_id=" +
          `${employee}` +
          "&from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&dept=" +
          `${depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const url = window.URL.createObjectURL(
        new Blob([attendedexportlist.data])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "unique-client-report.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      return false;
    }
  };

  const handlefromdatechange = (e) => {
    setFromdate(e.target.value);
  };

  const handletodatechange = (e) => {
    setTodate(e.target.value);
  };

  const handleemployeechange = (e) => {
    setSelectedemp(e);
    setEmployee(e.value);
  };
  const handledepartment = (e) => {
    setSelectedDept(e);
    setDepts(e.value);
    loadusers(e.value);
  };
  const handlecallchange = (e) => {
    setCalltype(e.target.value);
  };

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadusers(depts);
    clientlist(1);
    loadDepartment();
  }, []);

  const loadusers = async (Depts) => {
    try {
      const users = await axios.get(
        APP_URL + "user-filter-crm?department=" + `${Depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      setEmployeelist(users_list_data.data.user_list);
    } catch (e) {
      return null;
    }
  };

  /*----------load department------------*/

  const loadDepartment = async () => {
    const department_details = await axios.get(APP_URL + "departments", {
      headers: { Authorization: `Bearer ${token}` },
    });
    var js_data_department = JSON.parse(
      JSON.stringify(department_details.data)
    );
    if (js_data_department.status == 1) {
      setDepartments(js_data_department.data.departments);
    }
  };

  const employeedata = [];
  employeelist.forEach((item) => {
    employeedata.push({
      value: item.id,
      label: item.name + "-" + item.user_unique_code,
    });
  });
  const departmentData = [];
  departments.forEach((item) => {
    departmentData.push({ value: item.id, label: item.department_name });
  });
  return (
    <>
      <div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar />
          <ToastContainer />
          <div className="content-wrapper container">
            <div className="page-content">
              <section className="row">
                <div className="col-12 col-lg-12">
                  <section className="section">
                    <div className="row" id="table-hover-row">
                      <div className="col-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <h5>
                              Client Report (
                              {clientData && clientData.total_data})
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="">
                                  <table className="table table table-borderless mb-0">
                                    <thead>
                                      <tr>
                                        <th>From date</th>
                                        <th>To date</th>
                                        <th>Select Department</th>
                                        <th>Select Employee</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <input
                                            type="date"
                                            className="form-control"
                                            onChange={(e) =>
                                              handlefromdatechange(e)
                                            }
                                            value={fromdate}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="date"
                                            className="form-control"
                                            onChange={(e) =>
                                              handletodatechange(e)
                                            }
                                            value={todate}
                                          />
                                        </td>

                                        <td style={{ width: "250px" }}>
                                          <Select
                                            value={selectedDept}
                                            onChange={(e) =>
                                              handledepartment(e)
                                            }
                                            options={departmentData}
                                          />
                                        </td>

                                        <td style={{ width: "250px" }}>
                                          <Select
                                            value={selectedemp}
                                            onChange={(e) =>
                                              handleemployeechange(e)
                                            }
                                            options={employeedata}
                                          />
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-outline-success"
                                            onClick={() => clientlist(1)}
                                          >
                                            Apply
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-md-12">
                                <div className="col-sm-12">
                                  <h6>Reports</h6>
                                </div>

                                <button
                                  className="btn btn-success"
                                  onClick={exportUniqueClientReport}
                                >
                                  <i className="fa fa-download"></i> Export
                                </button>
                                <div className="table-responsive pb-5 mt-1">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th style={{ width: "200px" }}>
                                          Agent name
                                        </th>
                                        <th>Agent code</th>
                                        <th>Client No</th>
                                        <th>Total Calls</th>
                                        <th>Total Duration</th>
                                        <th>Incoming Call</th>
                                        <th>Incoming Duration</th>
                                        <th>Outgoing Call</th>
                                        <th>Outgoing Duration</th>
                                        <th>Missed</th>
                                        <th>Last Call Details</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {loading == true ? (
                                        <>
                                          {/* <tr>
                                            <td colSpan="11">
                                              <Loader />
                                            </td>
                                          </tr> */}
                                          <TableSkeleton count={10} />
                                        </>
                                      ) : (
                                        <>
                                          {currentRecords?.length > 0 ?
                                          currentRecords?.map(
                                              (item, index) => (
                                                <tr>
                                                  <td>{item.agent_name}</td>
                                                  <td>{item.agent_code}</td>
                                                  <td>
                                                    {
                                                      item.client_update_contact_no
                                                    }
                                                  </td>
                                                  <td>{item.total_call}</td>
                                                  <td>
                                                    {item.total_call_duration}
                                                  </td>
                                                  <td>
                                                    {item.total_incoming_call}
                                                  </td>
                                                  <td>
                                                    {
                                                      item.total_incoming_call_duration
                                                    }
                                                  </td>
                                                  <td>
                                                    {item.total_outgoing_call}
                                                  </td>
                                                  <td>
                                                    {
                                                      item.total_outgoing_call_duration
                                                    }
                                                  </td>
                                                  <td>
                                                    {item.total_missed_call}
                                                  </td>
                                                  <td>0</td>
                                                </tr>
                                              )
                                            ) : (
                                              <tr>
                                                <td colSpan="11">
                                                  <div
                                                    style={{
                                                      textAlign: "center",
                                                      fontSize: "18px",
                                                      fontWeight: "bold",                                                      
                                                    }}
                                                  >
                                                    No Data Found
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          }
                                        </>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div
                                  style={{ textAlign: "right", float: "right" }}
                                >
                                  <Paginationcustom
                                    data={nPages}
                                    changepage={changepage}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Clientreports;
