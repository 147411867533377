import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const TableSkeleton = ({ count }) => {
  return (
    <>
      {Array.from({ length: 7 }, (_, index) => (
        <tr key={index}>
          <td className="fs--1 align-middle">
            <Skeleton />
          </td>
          {Array.from({ length: count ? count : 8 }, (_, index) => (
            <td key={index} className="align-middle p-1">
              <Skeleton />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default TableSkeleton;
