import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Topbar from "../../../layout/topbar";
import Footer from "../../../layout/footer";
import { APP_URL } from "../../../validation/basic";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditRole = () => {
  const [roles, setRoles] = useState([]);
  const [menupermission, setMenupermission] = useState([]);
  const [rolename, setRolename] = useState("");
  const [rolelevel, setRolelevel] = useState([]);
  const [rolemenu, setRolemenu] = useState([]);
  const [rolepermission, setRolepermission] = useState([]);
  const [rolenameerror, setRolenameerror] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  let { id } = useParams();
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    saverole_list();
    listpermission();
    list_roles();
  }, []);
  const saverole_list = async () => {
    try {
      const getsaverole = await axios.get(
        APP_URL + "role-permissions/" + `${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let response_role = JSON.parse(JSON.stringify(getsaverole.data));
      if (response_role.status == 1) {
        setRolename(response_role.data.role_permission.role.role_name);
        let role_level_split =
          response_role.data.role_permission.role_level.split(",");
        setRolelevel(role_level_split);
        let role_menu_split =
          response_role.data.role_permission.menu_id.split(",");
        setRolemenu(role_menu_split);
        let role_permission_split =
          response_role.data.role_permission.permissions.split(",");
        setRolepermission(role_permission_split);
      }
    } catch (e) {
      toast.error("something went wrong");
      return false;
    }
  };

  const listpermission = async () => {
    try {
      const permissions = await axios.get(APP_URL + "permissions", {
        headers: { Authorization: `Bearer ${token}` },
      });
      let permission_list = JSON.parse(JSON.stringify(permissions.data));
      if (permission_list.status == 1) {
        setMenupermission(permission_list.data.permissions);
      }
    } catch (e) {
      return false;
    }
  };

  const list_roles = async () => {
    try {
      const roles_response = await axios.get(APP_URL + "roles", {
        headers: { Authorization: `Bearer ${token}` },
      });
      let role_level_data = JSON.parse(JSON.stringify(roles_response.data));
      if (role_level_data.status == 1) {
        setRoles(role_level_data.data.roles);
      }
    } catch (e) {
      return false;
    }
  };

  const hanlderolename = (e) => {
    setRolename(e.target.value);
  };

  const handlerolelever = (e) => {
    if (e.target.checked) {
      setRolelevel([...rolelevel, e.target.value]);
    } else {
      let index = rolelevel.indexOf(e.target.value);
      rolelevel.splice(index, 1);
    }
    list_roles();
  };

  const handlerolemenu = (e) => {
    if (e.target.checked) {
      setRolemenu([...rolemenu, e.target.value]);
    } else {
      let index = rolemenu.indexOf(e.target.value);
      rolemenu.splice(index, 1);
    }
    listpermission();
  };

  const handlerolepermission = (e) => {
    if (e.target.checked) {
      setRolepermission([...rolepermission, e.target.value]);
    } else {
      let index = rolepermission.indexOf(e.target.value);
      rolepermission.splice(index, 1);
    }
    listpermission();
  };

  const validate_role = () => {
    if (rolename == "") {
      setRolenameerror(true);
    } else {
      setRolenameerror(false);
    }
  };
  const RoleSubmit = async () => {
    validate_role();
    if (rolename == "") {
      return false;
    } else {
      try {
        let form = new URLSearchParams();
        form.append("role_name", rolename);
        form.append("role_level[]", rolelevel);
        form.append("role_menu[]", rolemenu);
        form.append("role_permission[]", rolepermission);
        const addroleprocess = await axios.put(
          APP_URL + "role-permissions/" + `${id}`,
          form,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        let addrole_success = JSON.parse(JSON.stringify(addroleprocess.data));
        if (addrole_success.status == 1) {
          toast.success("role updated successfull");
        } else {
          toast.error("something went wrong");
        }
      } catch (e) {
        toast.error("something went wrong");
        return false;
      }
    }
  };

  const checkrole_level = (val) => {
    return rolelevel.some((item) => val == item);
  };

  const handlerole_menu_check = (val) => {
    return rolemenu.some((item) => val == item);
  };

  const handlerole_permission_check = (val) => {
    return rolepermission.some((item) => val == item);
  };

  return (
    <>
      <div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar />
          <ToastContainer />
          <div className="content-wrapper container">
            <div className="page-content">
              <section className="row">
                <div className="col-12 col-lg-12">
                  <section className="section">
                    <div className="row" id="table-hover-row">
                      <div className="col-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <h5>Edit Role</h5>
                          </div>
                          <div className="card-body mt-4">
                            <div className="row">
                              <div
                                className="col-md-2"
                                style={{ textAlign: "right" }}
                              >
                                <label>Role</label>
                              </div>
                              <div className="col-md-5 form-group">
                                <input
                                  type="text"
                                  id="role"
                                  className={
                                    "form-control " +
                                    (rolenameerror == true ? "invalid" : null)
                                  }
                                  name="mobile"
                                  placeholder="Enter Role"
                                  onChange={(e) => hanlderolename(e)}
                                  value={rolename}
                                />
                              </div>
                              <div className="col-md-5"></div>
                            </div>

                            <div className="row">
                              <div
                                className="col-md-2"
                                style={{ textAlign: "right" }}
                              >
                                <label>Role Level : </label>
                              </div>
                              <div className="col-md-10 form-group">
                                <div className="row">
                                  {roles.length != 0
                                    ? roles.map((item, index) => (
                                        <>
                                          <div className="col-sm-2">
                                            <input
                                              type="checkbox"
                                              className="role_level_check"
                                              style={{ marginRight: "10px" }}
                                              onClick={(e) =>
                                                handlerolelever(e)
                                              }
                                              value={item.id}
                                              checked={checkrole_level(item.id)}
                                            />
                                            <span
                                              style={{ marginRight: "10px" }}
                                            >
                                              {" "}
                                              {item.role_name}
                                            </span>
                                          </div>
                                        </>
                                      ))
                                    : "No role created yet"}
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12 mt-3">
                              <div className="table-responsive">
                                <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Menus</th>
                                      <th>Permission</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {menupermission.map((item, index) => (
                                      <>
                                        <tr>
                                          <td>
                                            <input
                                              type="checkbox"
                                              style={{ marginRight: "10px" }}
                                              value={item.id}
                                              onClick={(e) => handlerolemenu(e)}
                                              checked={handlerole_menu_check(
                                                item.id
                                              )}
                                            />
                                            <span
                                              style={{ marginRight: "10px" }}
                                            >
                                              {item.menu_name}
                                            </span>
                                          </td>
                                          <td>
                                            <div className="row">
                                              {item.permission.map((a, v) => (
                                                <>
                                                  <div className="col-md-3">
                                                    <input
                                                      type="checkbox"
                                                      style={{
                                                        marginRight: "10px",
                                                      }}
                                                      value={a.id}
                                                      onClick={(e) =>
                                                        handlerolepermission(e)
                                                      }
                                                      checked={handlerole_permission_check(
                                                        a.id
                                                      )}
                                                    />
                                                    <span
                                                      style={{
                                                        marginRight: "10px",
                                                      }}
                                                    >
                                                      {a.permission}
                                                    </span>
                                                  </div>
                                                </>
                                              ))}
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-4">
                                <button
                                  className="btn btn-success"
                                  onClick={() => RoleSubmit()}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRole;
