import React, { useState, useEffect } from "react";
import { APP_URL } from "../../validation/basic";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Summary = ({ prevStep, user, setUser, edituser, setEdituser, id }) => {
  const {
    company_id,
    department_id,
    name,
    mobile,
    email,
    is_password,
    usepassword,
    cnfpassword,
    roll_id,
    reporting,
    is_masking,
    is_allow,
  } = user;
  const [reportinghead, setReportinghead] = useState([]);
  const [reportingerror, setReportingerror] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  let history = useNavigate();
  const selectreporting = (e) => {
    let reprt = e.target.value;
    setUser({ ...user, reporting: reprt });
  };

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    load_reporting();
  }, []);

  const load_reporting = async () => {
    try {
      const reporting_manager = await axios.get(
        APP_URL +
          "get-role-user-list?role_id=" +
          `${roll_id}` +
          "&dept_id=" +
          `${department_id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let reporting_response = JSON.parse(
        JSON.stringify(reporting_manager.data)
      );
      if (reporting_response.status == 1) {
        setReportinghead(reporting_response.data.users);
      }
    } catch (e) {
      return false;
    }
  };

  const finalsubmitBtn = async () => {
    try {
      const register_user = await axios.post(APP_URL + "register", user, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let register_user_response = JSON.parse(
        JSON.stringify(register_user.data)
      );
      if (register_user_response.status == 1) {
        toast.success("user register successfull");
        history("/master/users/user");
      } else {
        toast.error(register_user_response.data);
      }
    } catch (e) {
      return false;
      toast.error("something went wrong");
    }
  };

  const updateuserdetails = async () => {
    try {
      const update_user = await axios.put(APP_URL + "users/" + `${id}`, user, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let register_user_response = JSON.parse(JSON.stringify(update_user.data));
      if (register_user_response.status == 1) {
        toast.success("user updated successfull");
        history("/master/users/user");
      } else {
        toast.error(register_user_response.message);
      }
    } catch (e) {
      return false;
      toast.error("something went wrong");
    }
  };
  return (
    <>
      <div className="row pt-3">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className="row mb-2">
            <div className="col-md-4" style={{ textAlign: "right" }}>
              <label>
                Select Reporting head{" "}
                <sup style={{ color: "red" }}>
                  <i className="fa fa-asterisk" style={{ fontSize: "8px" }}></i>
                </sup>
              </label>
            </div>
            <div className="col-md-8 form-group">
              <select
                className={
                  "form-control " + (reportingerror == true ? "invalid" : null)
                }
                onChange={(e) => selectreporting(e)}
              >
                <option value="0">Select reporting head</option>
                {reportinghead?.map((item, index) => (
                  <>
                    {reporting == item.id ? (
                      <option value={item.id} selected>
                        {item?.name}-{item?.user_unique_code} (
                        {item?.role?.role_name})
                      </option>
                    ) : (
                      <option value={item.id}>
                        {item?.name}-{item?.user_unique_code} (
                        {item?.role?.role_name})
                      </option>
                    )}
                  </>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
        <div className="col-12 d-flex justify-content-end">
          <button
            className="btn btn-light-secondary me-1 mb-1"
            onClick={prevStep}
          >
            Previous
          </button>
          {typeof id === "undefined" ? (
            <button
              className="btn btn-primary me-1 mb-1"
              onClick={finalsubmitBtn}
            >
              Submit
            </button>
          ) : (
            <button
              className="btn btn-primary me-1 mb-1"
              onClick={updateuserdetails}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Summary;
