import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../../layout/topbar";
import Footer from "../../layout/footer";
import { APP_URL, getdates } from "../../validation/basic";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paginationcustom from "../../validation/pagination";
import Chart from "react-apexcharts";
import Select from "react-select";
import Loader from "../../loader/loader";
const AppUsage = () => {
  const [fromdate, setFromdate] = useState(getdates(0));
  const [todate, setTodate] = useState(getdates(0));
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState("");
  const [calltype, setCalltype] = useState("");
  const [loading, setLoading] = useState(false);
  const [uniqueclients, setUniqueclients] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [selectedemp, setSelectedemp] = useState("");
  const [usagereport, setUsagereport] = useState({
    whastApp_use: "0",
    BwhatsApp_use: "0",
    facebook_use: "0",
    instagram_use: "0",
    youtube_use: "0",
    chrome_use: "0",
    total_usage: "0",
    social_duration: "0",
    entertainment_duration: "0",
    other_duration: "0",
    emp_name: "",
    emp_code: "",
  });

  const handlefromdatechange = (e) => {
    setFromdate(e.target.value);
  };

  const handletodatechange = (e) => {
    setTodate(e.target.value);
  };

  const handleemployeechange = (e) => {
    setSelectedemp(e);
    setEmployee(e.value);
  };
  const handlecallchange = (e) => {
    setCalltype(e.target.value);
  };

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadusers();
    clientlist();
  }, []);

  const {
    whastApp_use,
    BwhatsApp_use,
    facebook_use,
    instagram_use,
    youtube_use,
    chrome_use,
    total_usage,
    social_duration,
    entertainment_duration,
    other_duration,
    emp_name,
    emp_code,
  } = usagereport;

  const loadusers = async () => {
    try {
      const users = await axios.get(APP_URL + "users", {
        headers: { Authorization: `Bearer ${token}` },
      });
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      setEmployees(users_list_data.data.user_list);
    } catch (e) {
      return null;
    }
  };
  const clientlist = async () => {
    try {
      setLoading(true);
      const attendedlist = await axios.get(
        APP_URL +
          "get-app-uses-report?user_id=" +
          `${employee}` +
          "&from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let neverattended_data = JSON.parse(JSON.stringify(attendedlist.data));
      if (neverattended_data.status == 1) {
        setUsagereport({
          ...usagereport,
          whastApp_use: neverattended_data.data.usage_data.whastApp_use,
          BwhatsApp_use: neverattended_data.data.usage_data.BwhatsApp_use,
          facebook_use: neverattended_data.data.usage_data.facebook_use,
          instagram_use: neverattended_data.data.usage_data.instagram_use,
          youtube_use: neverattended_data.data.usage_data.youtube_use,
          chrome_use: neverattended_data.data.usage_data.chrome_use,
          total_usage: neverattended_data.data.usage_data.total_usage,
          social_duration: neverattended_data.data.usage_data.social_duration,
          entertainment_duration:
            neverattended_data.data.usage_data.entertainment_duration,
          other_duration: neverattended_data.data.usage_data.other_duration,
          emp_name: neverattended_data.data.usage_data.emp_name,
          emp_code: neverattended_data.data.usage_data.emp_code,
        });
        setLoading(false);
      }
    } catch (e) {
      toast.error("something went wrong");
      return false;
    }
  };

  const employeedata = [];
  employees.forEach((item) => {
    employeedata.push({
      value: item.id,
      label: item.name + "-" + item.user_unique_code,
    });
  });

  return (
    <>
      <div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar />
          <div className="content-wrapper container">
            <div className="page-content">
              <section className="row">
                <div className="col-12 col-lg-12">
                  <section className="section">
                    <div className="row" id="table-hover-row">
                      <div className="col-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <h5>App Usage Reports</h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="">
                                  <table className="table table table-borderless mb-0">
                                    <thead>
                                      <tr>
                                        <th>From date</th>
                                        <th>To date</th>
                                        <th>Select Employee</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <input
                                            type="date"
                                            className="form-control"
                                            onChange={(e) =>
                                              handlefromdatechange(e)
                                            }
                                            value={fromdate}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="date"
                                            className="form-control"
                                            onChange={(e) =>
                                              handletodatechange(e)
                                            }
                                            value={todate}
                                          />
                                        </td>
                                        <td style={{ width: "250px" }}>
                                          <Select
                                            value={selectedemp}
                                            onChange={(e) =>
                                              handleemployeechange(e)
                                            }
                                            options={employeedata}
                                          />
                                        </td>

                                        <td>
                                          <button
                                            className="btn btn-outline-success"
                                            onClick={clientlist}
                                          >
                                            Apply
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <hr />

                            <div className="row">
                              <div className="col-lg-12">
                                <h6>App Usage Reports</h6>
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <table className="table table-bordered">
                                    {loading == true ? (
                                      <>
                                        <Loader />
                                      </>
                                    ) : (
                                      <>
                                        <thead>
                                          <tr className="custom_bg">
                                            <th colSpan="6">
                                              {emp_name == ""
                                                ? "All Employee"
                                                : "Employee Name :" +
                                                  emp_name}{" "}
                                              {emp_code != "" ? emp_code : null}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <div className="col-lg-12">
                                                <Chart
                                                  series={[
                                                    {
                                                      name: "Total usage",
                                                      data: [total_usage],
                                                    },
                                                    {
                                                      name: "Entertainment",
                                                      data: [
                                                        entertainment_duration,
                                                      ],
                                                    },
                                                    {
                                                      name: "Social",
                                                      data: [social_duration],
                                                    },

                                                    {
                                                      name: "Other",
                                                      data: [other_duration],
                                                    },
                                                  ]}
                                                  options={{
                                                    labels: ["App Usage"],
                                                  }}
                                                  type="bar"
                                                  width={1000}
                                                  height={320}
                                                ></Chart>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-3">
                                                  <div className="card">
                                                    <div className="card-body">
                                                      <div className="row">
                                                        <div className="col-md-4">
                                                          <div
                                                            className="stats-icon"
                                                            style={{
                                                              background:
                                                                "#49c12b",
                                                            }}
                                                          >
                                                            <i className="fa fa-whatsapp"></i>
                                                          </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                          <h6 className="text-muted font-semibold">
                                                            WhatsApp
                                                          </h6>
                                                          <h6 className="font-extrabold mb-0">
                                                            {whastApp_use} Min
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-sm-3">
                                                  <div className="card">
                                                    <div className="card-body">
                                                      <div className="row">
                                                        <div className="col-md-4">
                                                          <div
                                                            className="stats-icon"
                                                            style={{
                                                              background:
                                                                "#49c12b",
                                                            }}
                                                          >
                                                            <i className="fa fa-whatsapp"></i>
                                                          </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                          <h6 className="text-muted font-semibold">
                                                            Bussiness
                                                          </h6>
                                                          <h6 className="font-extrabold mb-0">
                                                            {BwhatsApp_use} Min
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-sm-3">
                                                  <div className="card">
                                                    <div className="card-body">
                                                      <div className="row">
                                                        <div className="col-md-4">
                                                          <div
                                                            className="stats-icon"
                                                            style={{
                                                              background:
                                                                "#6539ed",
                                                            }}
                                                          >
                                                            <i className="fa fa-facebook"></i>
                                                          </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                          <h6 className="text-muted font-semibold">
                                                            Facebook
                                                          </h6>
                                                          <h6 className="font-extrabold mb-0">
                                                            {facebook_use} Min
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-sm-3">
                                                  <div className="card">
                                                    <div className="card-body">
                                                      <div className="row">
                                                        <div className="col-md-4">
                                                          <div
                                                            className="stats-icon"
                                                            style={{
                                                              background:
                                                                "#dd0b76",
                                                            }}
                                                          >
                                                            <i className="fa fa-instagram"></i>
                                                          </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                          <h6 className="text-muted font-semibold">
                                                            Instagram
                                                          </h6>
                                                          <h6 className="font-extrabold mb-0">
                                                            {instagram_use} Min
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-sm-3">
                                                  <div className="card">
                                                    <div className="card-body">
                                                      <div className="row">
                                                        <div className="col-md-4">
                                                          <div
                                                            className="stats-icon"
                                                            style={{
                                                              background:
                                                                "#dd0b0b",
                                                            }}
                                                          >
                                                            <i className="fa fa-youtube"></i>
                                                          </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                          <h6 className="text-muted font-semibold">
                                                            YouTube
                                                          </h6>
                                                          <h6 className="font-extrabold mb-0">
                                                            {youtube_use} Min
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-sm-3">
                                                  <div className="card">
                                                    <div className="card-body">
                                                      <div className="row">
                                                        <div className="col-md-4">
                                                          <div
                                                            className="stats-icon"
                                                            style={{
                                                              background:
                                                                "#ef3b4b",
                                                            }}
                                                          >
                                                            <i className="fa fa-chrome"></i>
                                                          </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                          <h6 className="text-muted font-semibold">
                                                            Chrome
                                                          </h6>
                                                          <h6 className="font-extrabold mb-0">
                                                            {chrome_use} Min
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-sm-3">
                                                  <div className="card">
                                                    <div className="card-body">
                                                      <div className="row">
                                                        <div className="col-md-4">
                                                          <div
                                                            className="stats-icon"
                                                            style={{
                                                              background:
                                                                "#ef3b4b",
                                                            }}
                                                          >
                                                            <i className="fa fa-android"></i>
                                                          </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                          <h6 className="text-muted font-semibold">
                                                            Others
                                                          </h6>
                                                          <h6 className="font-extrabold mb-0">
                                                            {other_duration} Min
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </>
                                    )}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default AppUsage;
