import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../../../layout/topbar";
import Footer from "../../../layout/footer";
import { APP_URL, token } from "../../../validation/basic";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableSkeleton from "../../../loader/TableSkeleton";

const Role = () => {
  const [roles, setRoles] = useState([]);
  const [permissionlist, setPermissionlist] = useState([]);
  const [accesspermission, setAccesspermission] = useState([]);
  const [menuids, setMenuids] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    list_roles();
  }, []);
  const list_roles = async () => {
    setLoading(true);
    try {
      const roles_response = await axios.get(APP_URL + "roles", {
        headers: { Authorization: `Bearer ${token}` },
      });
      let role_level_data = JSON.parse(JSON.stringify(roles_response.data));
      if (role_level_data.status == 1) {
        setRoles(role_level_data.data.roles);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      return false;
    }
  };

  const delete_role = async (index) => {
    if (!window.confirm("Are you sure want to delete this")) {
      return false;
    } else {
      try {
        const delete_role_response = await axios.delete(
          APP_URL + "roles/" + `${index}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        let response_delete = JSON.parse(
          JSON.stringify(delete_role_response.data)
        );
        if (response_delete.status == 1) {
          toast.success("deleted successfull");
          list_roles();
        } else {
          toast.error("something went wrong");
        }
      } catch (e) {
        return false;
      }
    }
  };

  const StatusChangeRole = async (index) => {
    if (!window.confirm("Are you sure want to change this")) {
      return false;
    } else {
      try {
        const delete_role_response = await axios.get(
          APP_URL + "roles/status/" + `${index}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        let response_delete = JSON.parse(
          JSON.stringify(delete_role_response.data)
        );
        if (response_delete.status == 1) {
          toast.success("status change successfull");
          list_roles();
        } else {
          toast.error("something went wrong");
        }
      } catch (e) {
        return false;
      }
    }
  };
  return (
    <>
      <div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar
            setMenuids={setMenuids}
            menuids={menuids}
            setAccesspermission={setAccesspermission}
            accesspermission={accesspermission}
            setPermissionlist={setPermissionlist}
            permissionlist={permissionlist}
          />
          <ToastContainer />
          <div className="content-wrapper container">
            <div className="page-content">
              <section className="row">
                <div className="col-12 col-lg-12">
                  <section className="section">
                    <div className="row" id="table-hover-row">
                      <div className="col-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <h5>Manage Role</h5>
                            <div className="buttons mt-4">
                              {permissionlist &&
                                permissionlist.map((p, ind) => (
                                  <>
                                    {accesspermission.some(
                                      (item) => p.id == item
                                    ) == true ? (
                                      <>
                                        {p.permission == "Add" ? (
                                          <Link to="/master/manage/add-role">
                                            <button className="btn btn-warning rounded-pill">
                                              <i class="fa fa-plus"></i>Add New
                                              Role
                                            </button>
                                          </Link>
                                        ) : null}
                                      </>
                                    ) : null}
                                  </>
                                ))}
                            </div>
                          </div>
                          <div className="card-body mt-4">
                            <div className="table-responsive pb-5">
                              <table className="table table-bordered table-hover mb-0">
                                <thead>
                                  <tr>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>ACTION</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  { loading ? (
                                    <>
                                    <TableSkeleton count={2} />
                                    </>
                                  ) :
                                  (roles?.map((item, index) => (
                                    <tr>
                                      <td>{item.role_name}</td>

                                      <td>
                                        {item.status == 1 ? (
                                          <span className="badge bg-light-success">
                                            Activate
                                          </span>
                                        ) : (
                                          <span className="badge bg-light-danger">
                                            Deactivate
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="buttons">
                                          {permissionlist &&
                                            permissionlist.map((p, ind) => (
                                              <>
                                                {accesspermission.some(
                                                  (item) => p.id == item
                                                ) == true ? (
                                                  <>
                                                    {p.permission ==
                                                    "Activate" ? (
                                                      <button
                                                        className="btn btn-warning rounded-pill btn-custom"
                                                        title="block number"
                                                        onClick={() =>
                                                          StatusChangeRole(
                                                            item.id
                                                          )
                                                        }
                                                      >
                                                        <i className="fa fa-ban"></i>
                                                      </button>
                                                    ) : null}
                                                    {p.permission ==
                                                    "Delete" ? (
                                                      <button
                                                        className="btn btn-danger rounded-pill btn-custom"
                                                        title="delete number"
                                                        onClick={(e) =>
                                                          delete_role(item.id)
                                                        }
                                                      >
                                                        <i className="fa fa-trash"></i>
                                                      </button>
                                                    ) : null}
                                                    {p.permission == "Edit" ? (
                                                      <Link
                                                        to={
                                                          "/master/manage/edit-role/" +
                                                          item.id
                                                        }
                                                      >
                                                        <button
                                                          className="btn btn-primary rounded-pill btn-custom"
                                                          title="edit role"
                                                        >
                                                          <i className="fa fa-pencil"></i>
                                                        </button>
                                                      </Link>
                                                    ) : null}
                                                  </>
                                                ) : null}
                                              </>
                                            ))}
                                        </div>
                                      </td>
                                    </tr>
                                  )))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Role;
