import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../layout/footer";
import Master from "../../layout/master";
import Topbar from "../../layout/topbar";
import { APP_URL } from "../../validation/basic";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Finalprocess from "./connection/final_process";

const ConnectDevice = () => {
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [step, setStep] = useState(1);
  const [connectcode, setConnectcode] = useState();
  const [token, setToken] = useState(localStorage.getItem("token"));
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadDepartment();
  }, []);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const loadDepartment = async () => {
    const department_details = await axios.get(
      APP_URL + "departments?status=2",
      { headers: { Authorization: `Bearer ${token}` } }
    );
    var js_data_department = JSON.parse(
      JSON.stringify(department_details.data)
    );
    if (js_data_department.status == 1) {
      setDepartments(js_data_department.data.departments);
    }
  };

  return (
    <>
      <div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar />
          <ToastContainer />
          <div className="content-wrapper container">
            <div className="page-content">
              <section className="row">
                <div className="col-12 col-lg-12">
                  <section className="section">
                    <div className="row" id="table-hover-row">
                      <div className="col-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <h5>Connect Device</h5>
                          </div>
                          <div className="card-body">
                            <div className="col-md-12 mt-5 mb-4">
                              <h5
                                style={{
                                  textAlign: "center",
                                  fontSize: "20px",
                                  marginBottom: "30px",
                                }}
                              >
                                Step to connect your device with CRM
                              </h5>
                              <Finalprocess
                                step={step}
                                nextStep={nextStep}
                                prevStep={prevStep}
                                departments={departments}
                                employee={employees}
                                setEmployees={setEmployees}
                                connectcode={connectcode}
                                setConnectcode={setConnectcode}
                              />
                            </div>

                            <div
                              className="col-md-12"
                              style={{ textAlign: "right" }}
                            >
                              <div className="buttons">
                                {step == 1 ? (
                                  <>
                                    <button
                                      className="btn btn-warning"
                                      onClick={nextStep}
                                    >
                                      Next
                                    </button>
                                  </>
                                ) : step == 4 ? (
                                  <>
                                    <button
                                      className="btn btn-secondary"
                                      onClick={prevStep}
                                    >
                                      Previous
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="btn btn-secondary"
                                      onClick={prevStep}
                                    >
                                      Previous
                                    </button>
                                    <button
                                      className="btn btn-warning"
                                      onClick={nextStep}
                                    >
                                      Next
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectDevice;
