import ReactDOM, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../../layout/topbar";
import Footer from "../../layout/footer";
import Summary from "./summary";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Finalcomponent from "./final_component";
import axios from "axios";
import { APP_URL, getdates } from "../../validation/basic";
import Select from "react-select";

const Periodicreport = () => {
  const [fromdate, setFromdate] = useState(getdates(0));
  const [todate, setTodate] = useState(getdates(0));
  const [employee, setEmployee] = useState("");
  const [employeelist, setEmployeelist] = useState([]);
  const [calltype, setCalltype] = useState("");
  const [tab, setTab] = useState(1);
  const [periodicreport, setPeriodicreport] = useState([]);
  const [summary, setSummary] = useState();
  const [filter, setFilter] = useState([]);
  const [neverattended, setNeverattended] = useState([]);
  const [uniqueclients, setUniqueclients] = useState([]);
  const [calllog, setCalllog] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [selectedemp, setSelectedemp] = useState("");
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [selectedDept, setSelectedDept] = useState("");
  const [depts, setDepts] = useState("");
  const [summaryPageNo, setSummaryPageNo] = useState(1);
  const [neverPageNo, setNeverPageNo] = useState(1);
  const [uniquePageNo, setUniquePageNo] = useState(1);
  const [callPageNo, setCallPageNo] = useState(1);
  const [summaryData, setSummaryData] = useState(null);
  const [neverData, setNeverData] = useState(null);
  const [uniqueData, setUniqueData] = useState(null);
  const [callData, setCallData] = useState(null);
  const [periodicExportData, setPeriodicExportData] = useState([]);
  const [neverExportData, setNeverExportData] = useState([]);
  const [uniqueExportData, setUniqueExportData] = useState([]);
  const [callExportData, setCallExportData] = useState([]);
  const [dayWiseData, setDayWiseData] = useState([]);
  const [hourWiseData, setHourWiseData] = useState([]);
  const currentDate = new Date().toISOString().split("T")[0];

  const handlefromdatechange = (e) => {
    setFromdate(e.target.value);
  };

  const handletodatechange = (e) => {
    setTodate(e.target.value);
  };

  const handledepartment = (e) => {
    setSelectedDept(e);
    setDepts(e.value);
    loadusers(e.value);
  };

  const handleemployeechange = (e) => {
    setSelectedemp(e);
    setEmployee(e.value);
  };
  const handlecallchange = (e) => {
    setCalltype(e.target.value);
  };


  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadusers(depts);
    if (tab == 1) {
      filterBy(summaryPageNo);
    } else if (tab == 2) {
      filterBy(summaryPageNo);
    } else if (tab == 3) {
      NeverAttendedListData(neverPageNo, 10);
    } else if (tab == 5) {
      GetUniqueClientListData(uniquePageNo);
    } else if (tab == 6) {
      GetCallLogHistoryData(callPageNo);
    } else if (tab == 7) {
      handledayWiseData();
    } else if (tab == 8) {
      handleHourWiseData();
    }

    loadDepartment();
  }, []);

  // function to get hour wise data
  const handleHourWiseData = async () => {
    if (fromdate > currentDate) {
      toast.error("The 'From date' should not exceed the current date.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        APP_URL +
          "periodic-report-hourly?from_date=" +
          fromdate +
          "&user_id=" +
          employee,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success == true) {
        setHourWiseData(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // fn to get day wise data
  const handledayWiseData = async () => {
    if (todate > currentDate) {
      toast.error("The 'To date' should not exceed the current date.");
      return;
    }
    if (todate < fromdate) {
      toast.error("The 'To Date' should not be less than the 'From Date'.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(
        APP_URL +
          "periodic-report-day-wise?from_date=" +
          fromdate +
          "&to_date=" +
          todate +
          "&user_id=" +
          employee,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success == true) {
        setDayWiseData(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const filterBy = async (page) => {
    if (fromdate == null && todate == null && employee == 0 && calltype == 0) {
      toast.error("please selecte filtered attribute");
      return false;
    } else {
      if (tab == 3) {
        NeverAttendedListData(1, 10);
      } else if (tab == 5) {
        GetUniqueClientListData(1);
      } else if (tab == 6) {
        GetCallLogHistoryData(1);
      } else if (tab == 7) {
        handledayWiseData();
      } else if (tab == 8) {
        handleHourWiseData();
      } else {
        try {
          setLoading(true);
          const periodic_report = await axios.get(
            APP_URL +
              "periodic-report?call_type=" +
              `${calltype}` +
              "&employee_id=" +
              `${employee}` +
              "&from_date=" +
              `${fromdate}` +
              "&to_date=" +
              `${todate}` +
              "&dept=" +
              `${depts}` +
              "&page=" +
              `${page}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          let js_data = JSON.parse(JSON.stringify(periodic_report.data));
          if (js_data.status == 1) {
            setPeriodicreport(js_data.data.getPeriodic);
            setSummary(js_data.data.summary);
            setSummaryData(js_data.data);
            setLoading(false);
          }
        } catch (e) {
          toast.error("something went wrong");
          return false;
        }
      }
    }
  };

  const exportPeriodicReportDetails = async () => {
    /*--------export periodic report-------*/
    try {
      const periodic_report_export = await axios.get(
        APP_URL +
          "periodic-report-export?call_type=" +
          `${calltype}` +
          "&employee_id=" +
          `${employee}` +
          "&from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&dept=" +
          `${depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const url = window.URL.createObjectURL(
        new Blob([periodic_report_export.data])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "summary-report.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      return false;
    }
  };

  /*-------------never attended list--------*/

  const NeverAttendedListData = async (page, limit) => {
    try {
      setLoading(true);
      const attendedlist = await axios.get(
        APP_URL +
          "never-attended-report?call_type=" +
          `${calltype}` +
          "&employee_id=" +
          `${employee}` +
          "&from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&dept=" +
          `${depts}` +
          "&page=" +
          `${page}` +
          "&limit=" +
          `${limit}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let neverattended_data = JSON.parse(JSON.stringify(attendedlist.data));
      if (neverattended_data.status == 1) {
        setNeverattended(neverattended_data.data.getPeriodic);
        setNeverData(neverattended_data.data);
        setLoading(false);
      }
    } catch (e) {
      return false;
    }
  };

  const neverAttendedReportExport = async () => {
    /*-------never attended report export-----------*/
    try {
      const attendedexportlist = await axios.get(
        APP_URL +
          "never-attended-report-export?call_type=" +
          `${calltype}` +
          "&employee_id=" +
          `${employee}` +
          "&from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&dept=" +
          `${depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const url = window.URL.createObjectURL(
        new Blob([attendedexportlist.data])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "neverattended-report.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      return false;
    }
  };

  /*-----------get unique client list-----------*/

  const GetUniqueClientListData = async (page) => {
    try {
      setLoading(true);
      const clientlist = await axios.get(
        APP_URL +
          "get-unique-client-report?status=" +
          `${calltype}` +
          "&user_id=" +
          `${employee}` +
          "&from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&dept=" +
          `${depts}` +
          "&page=" +
          `${page}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let clientlist_data = JSON.parse(JSON.stringify(clientlist.data));
      if (clientlist_data.status == 1) {
        setUniqueclients(clientlist_data.data.calllogs);
        setUniqueData(clientlist_data.data);
        setLoading(false);
      }
    } catch (e) {
      return false;
    }
  };

  const UniqueReportExport = async () => {
    /*--------unique client report export-----------*/

    try {
      const clientexportlist = await axios.get(
        APP_URL +
          "get-unique-client-report-export?status=" +
          `${calltype}` +
          "&user_id=" +
          `${employee}` +
          "&from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&dept=" +
          `${depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const url = window.URL.createObjectURL(new Blob([clientexportlist.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "unique-client-report.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      return false;
    }
  };

  /*-----------get call log history list-----------*/

  const GetCallLogHistoryData = async (page) => {
    try {
      setLoading(true);
      const loghistory = await axios.get(
        APP_URL +
          "get-call-log-report?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&user_id=" +
          `${employee}` +
          "&status=" +
          `${calltype}` +
          "&dept=" +
          `${depts}` +
          "&page=" +
          `${page}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let history_list = JSON.parse(JSON.stringify(loghistory.data));
      if (history_list.status == 1) {
        setCalllog(history_list.data.call_log);
        setCallData(history_list.data);
        setLoading(false);
      }
    } catch (e) {
      return false;
    }
  };

  const callLogExportHistory = async () => {
    /*---------export call log history---------*/
    try {
      const logexporthistory = await axios.get(
        APP_URL +
          "get-call-logs-report-export?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&user_id=" +
          `${employee}` +
          "&status=" +
          `${calltype}` +
          "&dept=" +
          `${depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const url = window.URL.createObjectURL(new Blob([logexporthistory.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "calllog-report.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      return false;
    }
  };

  const loadusers = async (Depts) => {
    try {
      const users = await axios.get(
        APP_URL + "user-filter-crm?department=" + `${Depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      setEmployeelist(users_list_data.data.user_list);
    } catch (e) {
      return null;
    }
  };

  /*----------load department------------*/

  const loadDepartment = async () => {
    const department_details = await axios.get(APP_URL + "departments", {
      headers: { Authorization: `Bearer ${token}` },
    });
    var js_data_department = JSON.parse(
      JSON.stringify(department_details.data)
    );
    if (js_data_department.status == 1) {
      setDepartments(js_data_department.data.departments);
    }
  };

  const tabchange = (e) => {
    let data = e.target.dataset.id;
    if (data == 1) {
      filterBy(summaryPageNo);
    } else if (data == 2) {
      filterBy(summaryPageNo);
    } else if (data == 3) {
      NeverAttendedListData(neverPageNo, 10);
    } else if (data == 5) {
      GetUniqueClientListData(uniquePageNo);
    } else if (data == 6) {
      GetCallLogHistoryData(callPageNo);
    } else if (data == 7) {
      handledayWiseData();
    } else if (data == 8) {
      handleHourWiseData();
    }
    setTab(data);
  };

  const employeedata = [];
  employeelist.forEach((item) => {
    employeedata.push({
      value: item.id,
      label: item.name + "-" + item.user_unique_code,
    });
  });

  const departmentData = [];
  departments.forEach((item) => {
    departmentData.push({ value: item.id, label: item.department_name });
  });

  return (
    <>
      <div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar />
          <ToastContainer />
          <div className="content-wrapper container">
            <div className="page-content">
              <section className="row">
                <div className="col-12 col-lg-12">
                  <section className="section">
                    <div className="row" id="table-hover-row">
                      <div className="col-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <h5>Periodic Report</h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="">
                                  <table className="table table table-borderless mb-0">
                                    <thead>
                                      <tr>
                                        <th>From date</th>
                                        <th>To date</th>
                                        <th>Select Department</th>
                                        <th>Select Employee</th>
                                        <th>Select Call type</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <input
                                            type="date"
                                            className="form-control"
                                            value={fromdate}
                                            onChange={(e) =>
                                              handlefromdatechange(e)
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="date"
                                            className="form-control"
                                            value={todate}
                                            onChange={(e) =>
                                              handletodatechange(e)
                                            }
                                          />
                                        </td>
                                        <td style={{ width: "250px" }}>
                                          <Select
                                            value={selectedDept}
                                            onChange={(e) =>
                                              handledepartment(e)
                                            }
                                            options={departmentData}
                                          />
                                        </td>
                                        <td style={{ width: "250px" }}>
                                          <Select
                                            value={selectedemp}
                                            onChange={(e) =>
                                              handleemployeechange(e)
                                            }
                                            options={employeedata}
                                          />
                                        </td>

                                        <td>
                                          <select
                                            className="form-control"
                                            onChange={(e) =>
                                              handlecallchange(e)
                                            }
                                          >
                                            <option value="">
                                              Select call type
                                            </option>
                                            <option value="1">Incoming</option>
                                            <option value="2">Outgoing</option>
                                            <option value="3">Missed</option>
                                          </select>
                                        </td>

                                        <td>
                                          <button
                                            className="btn btn-outline-success"
                                            onClick={() =>
                                              filterBy(summaryPageNo)
                                            }
                                          >
                                            Apply
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-md-12">
                                <div className="col-sm-12">
                                  <h6>Reports</h6>
                                </div>

                                <div className="buttons">
                                  <button
                                    className={
                                      "btn btn-outline-primary " +
                                      (tab == 1 ? "active" : null)
                                    }
                                    data-id="1"
                                    onClick={tabchange}
                                  >
                                    <i className="fa fa-bar-chart"></i> Summary
                                  </button>
                                  <button
                                    className={
                                      "btn btn-outline-success " +
                                      (tab == 2 ? "active" : null)
                                    }
                                    data-id="2"
                                    onClick={tabchange}
                                  >
                                    <i className="fa fa-line-chart"></i>{" "}
                                    Analysis
                                  </button>
                                  <button
                                    className={
                                      "btn btn-outline-danger " +
                                      (tab == 3 ? "active" : null)
                                    }
                                    data-id="3"
                                    onClick={tabchange}
                                  >
                                    <i className="iconly-boldCall-Missed"></i>{" "}
                                    Never Attended
                                  </button>
                                  <button
                                    className={
                                      "btn btn-outline-secondary " +
                                      (tab == 5 ? "active" : null)
                                    }
                                    data-id="5"
                                    onClick={tabchange}
                                  >
                                    <i className="fa fa-user"></i> Unique
                                    Clients
                                  </button>
                                  <button
                                    className={
                                      "btn btn-outline-primary " +
                                      (tab == 6 ? "active" : null)
                                    }
                                    data-id="6"
                                    onClick={tabchange}
                                  >
                                    <i className="fa fa-list"></i> Call History
                                  </button>
                                  <button
                                    className={
                                      "btn btn-outline-success " +
                                      (tab == 7 ? "active" : null)
                                    }
                                    data-id="7"
                                    onClick={tabchange}
                                  >
                                    <i className="fa fa-sun-o"></i> Day Wise
                                    Analysis
                                  </button>
                                  <button
                                    className={
                                      "btn btn-outline-warning " +
                                      (tab == 8 ? "active" : null)
                                    }
                                    data-id="8"
                                    onClick={tabchange}
                                  >
                                    <i className="fa fa-hourglass"></i> Hour
                                    Wise Analysis
                                  </button>
                                </div>

                                <Finalcomponent
                                  data={tab}
                                  periodic={periodicreport}
                                  summary_detail={summary}
                                  from_date={fromdate}
                                  to_date={todate}
                                  employee={employee}
                                  calltype={calltype}
                                  neverattended={neverattended}
                                  setNeverattended={setNeverattended}
                                  uniqueclients={uniqueclients}
                                  setUniqueclients={setUniqueclients}
                                  calllog={calllog}
                                  setCalllog={setCalllog}
                                  loading={loading}
                                  summaryData={summaryData}
                                  neverData={neverData}
                                  uniqueData={uniqueData}
                                  callData={callData}
                                  filterBy={filterBy}
                                  NeverAttendedListData={NeverAttendedListData}
                                  GetUniqueClientListData={
                                    GetUniqueClientListData
                                  }
                                  GetCallLogHistoryData={GetCallLogHistoryData}
                                  periodicExportData={periodicExportData}
                                  neverExportData={neverExportData}
                                  uniqueExportData={uniqueExportData}
                                  callExportData={callExportData}
                                  exportPeriodicReportDetails={
                                    exportPeriodicReportDetails
                                  }
                                  neverAttendedReportExport={
                                    neverAttendedReportExport
                                  }
                                  UniqueReportExport={UniqueReportExport}
                                  callLogExportHistory={callLogExportHistory}
                                  dayWiseData={dayWiseData}
                                  hourWiseData={hourWiseData}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Periodicreport;
