import axios from "axios";
import React, { useEffect, useState } from "react";
import { APP_URL } from "../../validation/basic";
import { toast } from "react-toastify";
import TableSkeleton from "../../loader/TableSkeleton";
import { CSVLink } from "react-csv";

const DailyWiseAnalysis = ({dayWiseData, loading }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  // const [dayWiseData, setDayWiseData] = useState([]);
  // const [loading, setLoading] = useState(false);

  // console.log(dayWiseData)
  // console.log(loading)

  // useEffect(() => {
  //   setToken(localStorage.getItem("token"));
  //   const currentDate = new Date().toISOString().split("T")[0];

  //   const dayWiseData = async () => {
  //     if (to_date > currentDate) {
  //       toast.error("The 'To date' should not exceed the current date.");
  //       return;
  //     }
  //     if (to_date < from_date) {
  //       toast.error("The 'To Date' should not be less than the 'From Date'.");
  //       return;
  //     }
  //     setLoading(true);
  //     try {
  //       const response = await axios.get(
  //         APP_URL +
  //           "periodic-report-day-wise?from_date=" +
  //           from_date +
  //           "&to_date=" +
  //           to_date,
  //         { headers: { Authorization: `Bearer ${token}` } }
  //       );
  //       if (response.data.success == true) {
  //         setDayWiseData(response.data);
  //         setLoading(false);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       setLoading(false);
  //     }
  //   };
  //   dayWiseData();
  // }, [from_date, to_date]);

  // Helper function to format date
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const headers = [
    { label: "Date", key: "date" },
    { label: "Total Calls", key: "total_calls" },
    { label: "Total Incoming Calls", key: "total_incoming_calls" },
    { label: "Total Outgoing Calls", key: "total_outgoing_calls" },
    { label: "Total Missed Calls", key: "total_missed_calls" },
    { label: "Total Durations (in sec)", key: "total_durations" },
  ];

  const data = [];
  dayWiseData?.data?.forEach((item) => {
    data.push({
      date: item.date,
      total_calls: item?.total_calls ? item.total_calls : "0" ,
      total_incoming_calls: item?.incoming_calls ? item?.incoming_calls : "0" ,
      total_outgoing_calls: item?.outgoing_calls ? item?.outgoing_calls : "0" ,
      total_missed_calls: item?.missed_calls ? item?.missed_calls : "0" ,
      total_durations: item.total_duration ,
      
    });
  });

  return (
    <>
      <div className="row mt-3">
        <div className="col-lg-12">
       
          <h6>Day Wise Analysis</h6>
          <CSVLink
            data={data}
            headers={headers}
            filename={"day-wise-report.csv"}
            className="btn btn-success"
            target="_blank"
          >
            <i className="fa fa-download"></i> Export
          </CSVLink>
       
         
        </div>
        <div className="col-lg-12 mt-3">
          {/* <button
            className="btn btn-success"
            onClick={neverAttendedReportExport}
          >
            <i className="fa fa-download"></i> Export
          </button> */}
          <table className="table table-bordered">
            <thead>
              <tr>
                <th rowSpan="2" style={{ width: "200px" }}>
                  Date
                </th>
                <th rowSpan="2">Total Calls</th>
                <th rowSpan="2">Total Incoming Calls</th>
                <th rowSpan="2">Total Outgoing Calls</th>
                <th rowSpan="2">Total Missed Calls</th>
                <th rowSpan="2">Total Durations (in sec)</th>
              </tr>
            </thead>
            <tbody>
              {loading == true ? (
                <>
                  <TableSkeleton count={7} />
                </>
              ) : (
                <>
                  {dayWiseData?.data?.length > 0 ? (
                    dayWiseData?.data?.map((item, index) => (
                      <tr key={index}>
                        <td>{formatDate(item.date)}</td>
                        <td>{item?.total_calls ? item.total_calls : "0"}</td>
                        <td>
                          {item?.incoming_calls ? item?.incoming_calls : "0"}
                        </td>
                        <td>
                          {item?.outgoing_calls ? item?.outgoing_calls : "0"}
                        </td>
                        <td>{item?.missed_calls ? item?.missed_calls : "0"}</td>
                        <td>{item?.total_duration}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="11">
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          No Data Found
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
          {/* <div style={{ textAlign: "right", float: "right" }}>
            <Paginationcustom
              data={nPages}
              changepage={changepage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default DailyWiseAnalysis;
