import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import Topbar from "../../layout/topbar";
import Footer from "../../layout/footer";
import axios from "axios";
import { APP_URL } from "../../validation/basic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { CSVLink } from "react-csv";
// import Paginationcustom from "../../validation/pagination";
// import Select from "react-select";
// import Loader from "../../loader/loader";

const LeadTags = () => {
  const [tags, setTags] = useState("");
  const [inphidden, setInphidden] = useState("");
  const [isedit, setIsedit] = useState(false);
  const [errortags, setErrortags] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [isvalid, setIsvalid] = useState(false);
  const [btndisabled, setBtndisabled] = useState(false);
  const [permissionlist, setPermissionlist] = useState([]);
  const [accesspermission, setAccesspermission] = useState([]);
  const [menuids, setMenuids] = useState("");
  const [checkAdd, setCheckAdd] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadtags();
  }, [checkAdd]);
  const handlechange = (e) => {
    setTags(e.target.value);
  };
  const Validatetags = () => {
    if (tags === "") {
      setErrortags(false);
    } else {
      setErrortags(true);
    }

    if (tags === "" || tags === null) {
      return false;
    } else {
      return true;
    }
  };

  const loadtags = async () => {
    const company_list = await axios.get(APP_URL + "list-lead-tags", {
      headers: { Authorization: `Bearer ${token}` },
    });
    let companiesList = JSON.parse(JSON.stringify(company_list.data));
    setCompanies(companiesList.data.lead_tags);
  };

  const TagsSubmit = async () => {
    if (Validatetags() == true) {
      try {
        setCheckAdd(false);
        var form = new FormData();
        form.append("tags", tags);
        const lead_create = await axios.post(
          APP_URL + "create-lead-tags",
          form,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        var js_data_company_create = JSON.parse(
          JSON.stringify(lead_create.data)
        );
        if (js_data_company_create.status == 1) {
          setTags("");
          setCheckAdd(true);
          toast.success("tags created successfull");
        } else {
          toast.error("something went wrong");
        }
      } catch (e) {
        toast.error("something went wrong");
        return false;
      }
    } else {
      return false;
    }
  };

  /*------view lead tags---------*/

  const edit_view_tags = async (id) => {
    try {
      const company_view = await axios.get(
        APP_URL + "view-lead-tags/" + `${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const company_data = JSON.parse(JSON.stringify(company_view.data));
      if (company_data.status == 1) {
        setTags(company_data.data.lead_tags.tags);
        setInphidden(company_data.data.lead_tags.id);
        setIsedit(true);
      }
    } catch (e) {
      return false;
    }
  };

  /*-------------update lead tags--------*/
  const TagsUpdate = async () => {
    if (Validatetags() == true) {
      try {
        setCheckAdd(false);
        var form = new FormData();
        form.append("tags", tags);
        form.append("id", inphidden);
        const lead_create = await axios.post(
          APP_URL + "update-lead-tags",
          form,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        var js_data_company_create = JSON.parse(
          JSON.stringify(lead_create.data)
        );
        if (js_data_company_create.status == 1) {
          setTags("");
          setCheckAdd(true);
          setIsedit(false);
          toast.success("tags updated successfull");
        } else {
          toast.error("something went wrong");
        }
      } catch (e) {
        toast.error("something went wrong");
        return false;
      }
    } else {
      return false;
    }
  };

  /*-------delete tags---------*/

  const delete_tags = async (index) => {
    let id = index;
    if (!window.confirm("Are you sure want to delete this")) {
      return false;
    } else {
      try {
        setCheckAdd(false);
        const delete_company_details = await axios.get(
          APP_URL + "delete-lead-tags/" + `${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        var js_data_delete_company = JSON.parse(
          JSON.stringify(delete_company_details.data)
        );
        if (js_data_delete_company.status == 1) {
          setCheckAdd(true);
          toast.success("tags deleted successfull");
        }
      } catch (e) {
        toast.error("something went wrong");
      }
    }
  };

  /*------activate and deactivate--------*/

  const change_status_tags = async (index, status) => {
    var id = index;
    if (!window.confirm("Are you sure want to change this")) {
      return false;
    } else {
      try {
        setCheckAdd(false);
        if (status == 1) {
          var updated_status = 0;
        } else {
          var updated_status = 1;
        }
        var form = new FormData();
        form.append("status", updated_status);
        form.append("id", index);
        const activate_department = await axios.post(
          APP_URL + "activate-lead-tags",
          form,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        var js_data_change_status = JSON.parse(
          JSON.stringify(activate_department.data)
        );
        if (js_data_change_status.status == 1) {
          setCheckAdd(true);
          toast.success("status has been changed");
        } else {
          toast.error("something went wrong");
        }
      } catch (e) {
        toast.error("something went wrong");
      }
    }
  };

  /*-----------copy lead tags-------------*/

  const copyleadtags = async (index) => {
    let id = index;
    var TempText = document.createElement("input");
    TempText.value = id;
    document.body.appendChild(TempText);
    TempText.select();
    document.execCommand("copy");
    toast.success("copied successfull");
    document.body.removeChild(TempText);
  };

  return (
    <div id="app">
      <div id="main" className="layout-horizontal">
        <Topbar
          setMenuids={setMenuids}
          menuids={menuids}
          setAccesspermission={setAccesspermission}
          accesspermission={accesspermission}
          setPermissionlist={setPermissionlist}
          permissionlist={permissionlist}
        />
        <ToastContainer />
        <div className="content-wrapper container">
          <div className="page-heading">
            <h4>Lead Tags Details</h4>
          </div>
          <div className="page-content">
            <section className="row">
              <div className="col-12 col-lg-5">
                <section id="basic-vertical-layouts">
                  <div className="row match-height">
                    <div className="col-md-12 col-12">
                      <div className="card">
                        <div className="card-content">
                          <div className="card-body">
                            <div className="form-body">
                              <div className="row">
                                <div className="col-12">
                                  <input type="hidden" value={inphidden} />
                                  <div className="form-group">
                                    <label for="first-name-vertical">
                                      Tags
                                    </label>
                                    <input
                                      type="text"
                                      id="first-name-vertical"
                                      className={
                                        "form-control " +
                                        (errortags == false ? "invalid" : "")
                                      }
                                      name="tags_name"
                                      onChange={(e) => handlechange(e)}
                                      placeholder="Tags Name"
                                      value={tags}
                                    />
                                  </div>
                                </div>

                                <div className="col-12 d-flex justify-content-end">
                                  {btndisabled == false ? (
                                    <>
                                      {isedit == false ? (
                                        <button
                                          className="btn btn-primary me-1 mb-1"
                                          onClick={TagsSubmit}
                                        >
                                          Submit
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-primary me-1 mb-1"
                                          onClick={TagsUpdate}
                                        >
                                          Update
                                        </button>
                                      )}
                                    </>
                                  ) : (
                                    <button
                                      className="btn btn-primary me-1 mb-1"
                                      type="button"
                                      disabled
                                    >
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      Loading...
                                    </button>
                                  )}
                                  <button
                                    type="reset"
                                    className="btn btn-light-secondary me-1 mb-1"
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-12 col-lg-7">
                <section className="section">
                  <div className="row" id="table-hover-row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive pb-5">
                            <table className="table table-bordered table-hover mb-0">
                              <thead>
                                <tr>
                                  <th>Tags</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {companies &&
                                  companies.map((item, index) => (
                                    <tr>
                                      <td className="text-bold-500">
                                        {item.tags}
                                      </td>
                                      <td>
                                        {item.status == 1 ? (
                                          <span className="badge bg-light-success">
                                            Activate
                                          </span>
                                        ) : (
                                          <span className="badge bg-light-danger">
                                            Deactivate
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="buttons">
                                          {permissionlist &&
                                            permissionlist?.map((p, ind) => (
                                              <>
                                                {accesspermission?.some((item1) => p.id == item1) == true ? (
                                                  <>
                                                    {p.permission == "Activate" ? (
                                                      <button
                                                        className="btn btn-warning rounded-pill btn-custom"
                                                        onClick={() =>
                                                          change_status_tags(
                                                            item.id,
                                                            item.status
                                                          )
                                                        }
                                                      >
                                                        <i className="fa fa-ban"></i>
                                                      </button>
                                                    ) : null}
                                                    {p.permission == "Edit" ? (
                                                      <button
                                                        className="btn btn-success rounded-pill btn-custom"
                                                        onClick={() =>
                                                          edit_view_tags(
                                                            item.id
                                                          )
                                                        }
                                                      >
                                                        <i className="fa fa-pencil"></i>
                                                      </button>
                                                    ) : null}
                                                    {p.permission == "Delete" ? (
                                                      <button
                                                        className="btn btn-danger rounded-pill btn-custom"
                                                        onClick={() => delete_tags(item.id)}
                                                      >
                                                        <i className="fa fa-trash"></i>
                                                      </button>
                                                    ) : null}
                                                    {p.permission == "Copy" ? (
                                                      <button
                                                        className="btn btn-warning rounded-pill btn-custom"
                                                        onClick={(e) => copyleadtags(item.encrypt_tag_id)}
                                                        title="copy user id"
                                                      >
                                                        <i className="fa fa-copy"></i>
                                                      </button>
                                                    ) : null}
                                                  </>
                                                ) : null}
                                              </>
                                            ))}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LeadTags;
