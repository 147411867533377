import React, { useState, useEffect } from "react";
import Topbar from "../../layout/topbar";
import Footer from "../../layout/footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { APP_URL, BASE_URL } from "../../validation/basic";
import Chart from "react-apexcharts";
import Player from "../manage/Player";
import { Link } from "react-router-dom";

const LiveMonitoring = () => {

  const [employee, setEmployee] = useState([]);
  const [totalemployee, setTotalemployee] = useState(0);
  const [assigndevice, setAssigndevice] = useState(0);
  const [oncall, setOncall] = useState(0);
  const [onidle, setOnidle] = useState(0);
  const [activedevice, setActivedevice] = useState(0);
  const [callstatus, setCallStatus] = useState(0);
  const [initData, setInitData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    setInterval(employeelist, 10000);
  }, []);

  const Livenotfication = () => {
    return (
      <>
        <img
          src={BASE_URL + "/assets/images/giphy.gif"}
          style={{ width: "70px" }}
        />
      </>
    );
  };

  const employeelist = async () => {
    try {
      const employee_data = await axios.get(APP_URL + "live-monitor", {
        headers: { Authorization: `Bearer ${token}` },
      });
      let getemployee = JSON.parse(JSON.stringify(employee_data.data));
      if (getemployee.status == 1) {
        setEmployee(getemployee.data.getdata);
        setTotalemployee(getemployee.data.total_employee);
        setOncall(getemployee.data.total_oncall_employee);
        setOnidle(getemployee.data.total_idle_employee);
        setAssigndevice(getemployee.data.total_assign_device);
        setActivedevice(getemployee.data.total_active_device);
      }
    } catch (e) {
      toast.error("something went wrong");
    }
  };

  const filter_live_data = (e) => {
    let data = e.currentTarget.dataset.id;
    setCallStatus(data);
  };

  return (
    <>
      <div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar />
          <ToastContainer />
          <div className="content-wrapper container">
            <div className="page-content">
              <section className="row">
                <div className="col-12 col-lg-12">
                  <section className="section">
                    <div className="row" id="table-hover-row">
                      <div className="col-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                <h4 className="">Live Monitoring</h4>
                              </div>
                              <div
                                className="col-md-6"
                                style={{ textAlign: "right" }}
                              >
                                <span>
                                  <strong>Date - </strong>
                                  {new Date().toLocaleString()}{" "}
                                </span>

                                <Livenotfication />
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="row mt-3">
                              <div className="col-lg-6">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div
                                      className="card"
                                      style={{ background: "rgb(51 182 245)" }}
                                    >
                                      <div className="card-body">
                                        <div className="row">
                                          <div
                                            className="col-md-2"
                                            style={{ textAlign: "right" }}
                                          >
                                            <i
                                              className="fa fa-user"
                                              style={{
                                                color: "#fff",
                                                fontSize: "18px",
                                              }}
                                            ></i>
                                          </div>
                                          <div className="col-md-10">
                                            <h6
                                              className="font-extrabold mb-0"
                                              style={{
                                                color: "#fff",
                                                fontSize: "20px",
                                              }}
                                            >
                                              {assigndevice}/{totalemployee}
                                            </h6>
                                            <h6 style={{ color: "#fff" }}>
                                              Total Employee
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <Link
                                      to="#"
                                      data-id="0"
                                      onClick={(e) => filter_live_data(e)}
                                    >
                                      <div
                                        className="card"
                                        style={{
                                          background: "rgb(245 194 51)",
                                        }}
                                      >
                                        <div className="card-body">
                                          <div className="row">
                                            <div
                                              className="col-md-2"
                                              style={{ textAlign: "right" }}
                                            >
                                              <i
                                                className="fa fa-phone"
                                                style={{
                                                  color: "#fff",
                                                  fontSize: "18px",
                                                }}
                                              ></i>
                                            </div>
                                            <div className="col-md-10">
                                              <h5
                                                className="font-extrabold mb-0"
                                                style={{ color: "#fff" }}
                                              >
                                                {activedevice}
                                              </h5>
                                              <h6 style={{ color: "#fff" }}>
                                                Active Assign No
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>

                                  <div className="col-md-6">
                                    <Link
                                      to="#"
                                      data-id="2"
                                      onClick={(e) => filter_live_data(e)}
                                    >
                                      <div
                                        className="card"
                                        style={{
                                          background: "rgb(255 87 102)",
                                        }}
                                      >
                                        <div className="card-body">
                                          <div className="row">
                                            <div
                                              className="col-md-2"
                                              style={{ textAlign: "right" }}
                                            >
                                              <i
                                                className="fa fa-eye-slash"
                                                style={{
                                                  color: "#fff",
                                                  fontSize: "18px",
                                                }}
                                              ></i>
                                            </div>
                                            <div className="col-md-10">
                                              <h5
                                                className="font-extrabold mb-0"
                                                style={{ color: "#fff" }}
                                              >
                                                {onidle}
                                              </h5>
                                              <h6 style={{ color: "#fff" }}>
                                                Idle Call
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>

                                  <div className="col-md-6">
                                    <Link
                                      to="#"
                                      data-id="1"
                                      onClick={(e) => filter_live_data(e)}
                                    >
                                      <div
                                        className="card"
                                        style={{ background: "rgb(21 177 2)" }}
                                      >
                                        <div className="card-body">
                                          <div className="row">
                                            <div
                                              className="col-md-2"
                                              style={{ textAlign: "right" }}
                                            >
                                              <i
                                                className="fa fa-eye"
                                                style={{
                                                  color: "#fff",
                                                  fontSize: "18px",
                                                }}
                                              ></i>
                                            </div>
                                            <div className="col-md-10">
                                              <h5
                                                className="font-extrabold mb-0"
                                                style={{ color: "#fff" }}
                                              >
                                                {oncall}
                                              </h5>
                                              <h6 style={{ color: "#fff" }}>
                                                On Call
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <Chart
                                  series={[onidle, oncall]}
                                  options={{
                                    labels: ["Idle Call", "Ongoing Call"],
                                    colors: ["#ff5766", "#15b102"],
                                  }}
                                  type="pie"
                                  width={500}
                                  height={250}
                                ></Chart>
                              </div>
                            </div>
                            <div className="table-responsive pb-5 mt-4">
                              <table className="table table-bordered table-hover mb-0">
                                <thead>
                                  <tr>
                                    <th>sr.no</th>
                                    <th style={{ width: "200px" }}>
                                      Employee Name
                                    </th>
                                    <th>Employee code</th>
                                    <th>
                                      <div className="row">
                                        <div className="col-md-3">
                                          Assign No
                                        </div>

                                        <div className="col-md-3">
                                          Client No
                                        </div>
                                        <div className="col-md-2">Status</div>
                                        <div className="col-md-2">
                                          Last Call
                                        </div>
                                        <div className="col-md-2">
                                          Accessbility
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {employee?.map((item, index) => (
                                    <>
                                      {callstatus == 1 ? (
                                        <>
                                          {item.call_updated_state != null ? (
                                            item.call_updated_state.call_state == 1 ? (
                                              <>
                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{item.name}</td>
                                                  <td>
                                                    {item.user_unique_code}
                                                  </td>
                                                  <td>
                                                    <div className="row">
                                                      {item.call_status_details != null
                                                        ? item.call_status_details.map((a, v) => (
                                                              <>
                                                                {a.call_state_status != null ? (
                                                                  a.call_state_status.call_state == 1 ? (
                                                                    <>
                                                                      <div className="col-md-3 mb-2">
                                                                        {a.agent_phone_number}
                                                                      </div>
                                                                      <div className="col-md-3 mb-2">
                                                                        {a.call_state_status != null
                                                                          ? a.call_state_status.client_updated_no
                                                                          : null}
                                                                      </div>
                                                                      <div className="col-md-2 mb-2">
                                                                        {a.call_state_status != null ? (
                                                                          a.call_state_status.call_state == 1 ? (
                                                                            <span
                                                                              style={{
                                                                                background:"#15b102",
                                                                                paddingRight:"20px",
                                                                                borderRadius:"50%",
                                                                              }}
                                                                              title="On Call"
                                                                            ></span>
                                                                          ) : null
                                                                        ) : null}{" "}
                                                                      </div>
                                                                      <div className="col-md-2">
                                                                        {a.call_state_status != null ? (
                                                                          a.call_state_status.call_state != 1 ? (
                                                                            a.call_state_status.last_call != null ? (
                                                                              <Player
                                                                                url={
                                                                                  a.call_state_status.last_call.recording_link
                                                                                }
                                                                              />
                                                                            ) : null
                                                                          ) : null
                                                                        ) : null}
                                                                      </div>
                                                                      <div className="col-md-2">
                                                                        {a.is_service == 0 ? (
                                                                          <span className="badge bg-light-danger">
                                                                            OFF
                                                                          </span>
                                                                        ) : (
                                                                          <span className="badge bg-light-success">
                                                                            ON
                                                                          </span>
                                                                        )}
                                                                      </div>
                                                                    </>
                                                                  ) : null
                                                                ) : null}
                                                              </>
                                                            )
                                                          )
                                                        : null}
                                                    </div>
                                                  </td>
                                                </tr>
                                              </>
                                            ) : null
                                          ) : null}
                                        </>
                                      ) : callstatus == 2 ? (
                                        <>
                                          {item.call_updated_state != null ? (
                                            item.call_updated_state.call_state == 2 ? (
                                              <>
                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{item.name}</td>
                                                  <td>
                                                    {item.user_unique_code}
                                                  </td>
                                                  <td>
                                                    <div className="row">
                                                      {item.call_status_details != null
                                                        ? item.call_status_details.map((a, v) => (
                                                              <>
                                                                {a.call_state_status != null ? (
                                                                  a.call_state_status.call_state != 1 ? (
                                                                    <>
                                                                      <div className="col-md-3 mb-2">
                                                                        {a.agent_phone_number}
                                                                      </div>
                                                                      <div className="col-md-3 mb-2">
                                                                        {a.call_state_status != null
                                                                          ? a.call_state_status.client_updated_no
                                                                          : null}
                                                                      </div>
                                                                      <div className="col-md-2 mb-2">
                                                                        {a.call_state_status != null ? (
                                                                          a.call_state_status.call_state == 2 ? (
                                                                            <span
                                                                              style={{
                                                                                background:"#ff5766",
                                                                                paddingRight:"20px",
                                                                                borderRadius:"50%",
                                                                              }}
                                                                              title="Idle Call"
                                                                            ></span>
                                                                          ) : null
                                                                        ) : (
                                                                          <span
                                                                            style={{
                                                                              background:"#ff5766",
                                                                              paddingRight:"20px",
                                                                              borderRadius:"50%",
                                                                            }}
                                                                            title="Idle Call"
                                                                          ></span>
                                                                        )}{" "}
                                                                      </div>
                                                                      <div className="col-md-2">
                                                                        {a.call_state_status != null ? (
                                                                          a.call_state_status.call_state != 1 ? (
                                                                            a.call_state_status.last_call != null ? (
                                                                              <Player
                                                                                url={
                                                                                  a.call_state_status.last_call.recording_link
                                                                                }
                                                                              />
                                                                            ) : null
                                                                          ) : null
                                                                        ) : null}
                                                                      </div>
                                                                      <div className="col-md-2">
                                                                        {a.is_service == 0 ? (
                                                                          <span className="badge bg-light-danger">
                                                                            OFF
                                                                          </span>
                                                                        ) : (
                                                                          <span className="badge bg-light-success">
                                                                            ON
                                                                          </span>
                                                                        )}
                                                                      </div>
                                                                    </>
                                                                  ) : null
                                                                ) : (
                                                                  <>
                                                                    <div className="col-md-3 mb-2">
                                                                      {a.agent_phone_number}
                                                                    </div>
                                                                    <div className="col-md-3 mb-2">
                                                                      {a.call_state_status != null
                                                                        ? a.call_state_status.client_updated_no
                                                                        : null}
                                                                    </div>
                                                                    <div className="col-md-2 mb-2">
                                                                      {a.call_state_status != null ? (
                                                                        a.call_state_status.call_state == 2 ? (
                                                                          <span
                                                                            style={{
                                                                              background:"#ff5766",
                                                                              paddingRight:"20px",
                                                                              borderRadius:"50%",
                                                                            }}
                                                                            title="Idle Call"
                                                                          ></span>
                                                                        ) : null
                                                                      ) : (
                                                                        <span
                                                                          style={{
                                                                            background:"#ff5766",
                                                                            paddingRight:"20px",
                                                                            borderRadius:"50%",
                                                                          }}
                                                                          title="Idle Call"
                                                                        ></span>
                                                                      )}{" "}
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                      {a.call_state_status != null ? (
                                                                        a.call_state_status.call_state != 1 ? (
                                                                          a.call_state_status.last_call != null ? (
                                                                            <Player
                                                                              url={
                                                                                a.call_state_status.last_call.recording_link
                                                                              }
                                                                            />
                                                                          ) : null
                                                                        ) : null
                                                                      ) : null}
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                      {a.is_service ==
                                                                      0 ? (
                                                                        <span className="badge bg-light-danger">
                                                                          OFF
                                                                        </span>
                                                                      ) : (
                                                                        <span className="badge bg-light-success">
                                                                          ON
                                                                        </span>
                                                                      )}
                                                                    </div>
                                                                  </>
                                                                )}
                                                              </>
                                                            )
                                                          )
                                                        : null}
                                                    </div>
                                                  </td>
                                                </tr>
                                              </>
                                            ) : null
                                          ) : null}
                                        </>
                                      ) : (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>{item.name}</td>
                                          <td>{item.user_unique_code}</td>
                                          <td>
                                            <div className="row">
                                              {item.call_status_details != null
                                                ? item.call_status_details.map(
                                                    (a, v) => (
                                                      <>
                                                        <div className="col-md-3 mb-2">
                                                          {a.agent_phone_number}
                                                        </div>
                                                        <div className="col-md-3 mb-2">
                                                          {a.call_state_status !=
                                                          null
                                                            ? a
                                                                .call_state_status
                                                                .client_updated_no
                                                            : null}
                                                        </div>
                                                        <div className="col-md-2 mb-2">
                                                          {a.call_state_status !=
                                                          null ? (
                                                            a.call_state_status
                                                              .call_state ==
                                                            1 ? (
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "#15b102",
                                                                  paddingRight:
                                                                    "20px",
                                                                  borderRadius:
                                                                    "50%",
                                                                }}
                                                                title="On Call"
                                                              ></span>
                                                            ) : (
                                                              <span
                                                                style={{
                                                                  background:
                                                                    "#ff5766",
                                                                  paddingRight:
                                                                    "20px",
                                                                  borderRadius:
                                                                    "50%",
                                                                }}
                                                                title="Idle Call"
                                                              ></span>
                                                            )
                                                          ) : (
                                                            <span
                                                              style={{
                                                                background:
                                                                  "#ff5766",
                                                                paddingRight:
                                                                  "20px",
                                                                borderRadius:
                                                                  "50%",
                                                              }}
                                                              title="Idle Call"
                                                            ></span>
                                                          )}{" "}
                                                        </div>
                                                        <div className="col-md-2">
                                                          {a.call_state_status !=
                                                          null ? (
                                                            a.call_state_status
                                                              .call_state !=
                                                            1 ? (
                                                              a
                                                                .call_state_status
                                                                .last_call !=
                                                              null ? (
                                                                <Player
                                                                  url={
                                                                    a
                                                                      .call_state_status
                                                                      .last_call
                                                                      .recording_link
                                                                  }
                                                                />
                                                              ) : null
                                                            ) : null
                                                          ) : null}
                                                        </div>
                                                        <div className="col-md-2">
                                                          {a.is_service == 0 ? (
                                                            <span className="badge bg-light-danger">
                                                              OFF
                                                            </span>
                                                          ) : (
                                                            <span className="badge bg-light-success">
                                                              ON
                                                            </span>
                                                          )}
                                                        </div>
                                                      </>
                                                    )
                                                  )
                                                : null}
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveMonitoring;
