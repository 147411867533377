import React, { useState, useEffect } from "react";
import { APP_URL, token } from "../../validation/basic";
import axios from "axios";
import Paginationcustom from "../../validation/pagination";
import { CSVLink } from "react-csv";
import Loader from "../../loader/loader";
import TableSkeleton from "../../loader/TableSkeleton";
const Contact = ({
  contact,
  loading,
  permissionlist,
  accesspermission,
  contactData,
  contactlist,
  exportContactData,
  exportContactLogDetails,
}) => {
  const [currentPage, setCurrentPage] = useState(
    contactData != null ? contactData.page : 1
  );
  // const [recordsPerPage] = useState(contactData!=null?contactData.limit:null);
  /*--pagination start here---*/

  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = contact;

  const nPages = contactData != null ? contactData.total_page_count : null;
  //const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const changepage = (e, p) => {
    setCurrentPage(p);
    contactlist(parseInt(p));
  };

  /*---pagination end here---*/

  return (
    <>
      {permissionlist &&
        permissionlist?.map((p, ind) => (
          <>
            {accesspermission?.some((item) => p.id == item) == true ? (
              <>
                {p.permission == "Export" ? (
                  <button
                    className="btn btn-success mb-2"
                    onClick={exportContactLogDetails}
                  >
                    <i className="fa fa-download"></i> Export
                  </button>
                ) : null}
              </>
            ) : null}
          </>
        ))}

      <div className="table-responsive pb-5">
        <table className="table table-bordered table-hover mb-0">
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>Employee Code</th>
              <th>Name</th>
              <th>Mobile</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {loading == true ? (
              <>
                {/* <tr>
                  <td colSpan="10">
                    <Loader />
                  </td>
                </tr> */}
                <TableSkeleton count={4}/>
              </>
            ) : (
              <>
                {currentRecords?.length > 0 ?
                  currentRecords?.map((item, index) => (
                    <tr>
                      <td>{item.user.name}</td>
                      <td>{item.user.user_unique_code}</td>
                      <td>{item.name}</td>
                      <td>{item.mobile}</td>
                      <td>{item.email}</td>
                    </tr>
                  ))
                  : 
                  (
                    <tr>
                      <td colSpan="11">
                      <div
                        style={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "bold",
                        }}
                      >
                        No Data Found
                      </div>
                      </td>
                    </tr>
                    )
                }
              </>
            )}
          </tbody>
        </table>
      </div>
      <div style={{ textAlign: "right", float: "right" }}>
        <Paginationcustom
          data={nPages}
          changepage={changepage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default Contact;
