import ReactDOM, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../../layout/topbar";
import Footer from "../../layout/footer";
import { APP_URL, getdates } from "../../validation/basic";
import axios from "axios";
import { CSVLink } from "react-csv";
import Select from "react-select";
import Loader from "../../loader/loader";
import Paginationcustom from "../../validation/pagination";
import TableSkeleton from "../../loader/TableSkeleton";
const Employeereports = () => {
  const [reports, setReports] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [fromdate, setFromdate] = useState(getdates(0));
  const [todate, setTodate] = useState(getdates(0));
  const [employee, setEmployee] = useState("");
  const [calltype, setCalltype] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [selectedemp, setSelectedemp] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDept, setSelectedDept] = useState("");
  const [depts, setDepts] = useState("");
  const [employeeData, setEmployeeData] = useState(null);
  const [employeeExportData, setEmployeeExportData] = useState([]);
  /*--pagination start here---*/

  const [currentPage, setCurrentPage] = useState(
    employeeData != null ? employeeData.page : 1
  );
  // const [recordsPerPage] = useState(employeeData!=null?employeeData.limit:null);
  /*--pagination start here---*/

  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = reports;

  const nPages = employeeData != null ? employeeData.total_page_count : null;
  //const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const changepage = (e, p) => {
    setCurrentPage(p);
    employeereport(parseInt(p), 1);
  };
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadusers(depts);
    employeereport(1, 1);
    loadDepartment();
  }, []);

  const loadusers = async (Depts) => {
    try {
      const users = await axios.get(
        APP_URL + "user-filter-crm?department=" + `${Depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      setEmployees(users_list_data.data.user_list);
    } catch (e) {
      return null;
    }
  };

  /*----------load department------------*/

  const loadDepartment = async () => {
    const department_details = await axios.get(APP_URL + "departments", {
      headers: { Authorization: `Bearer ${token}` },
    });
    var js_data_department = JSON.parse(
      JSON.stringify(department_details.data)
    );
    if (js_data_department.status == 1) {
      setDepartments(js_data_department.data.departments);
    }
  };

  const employeereport = async (page, limit) => {
    try {
      setLoading(true);
      const employee_reports = await axios.get(
        APP_URL +
          "get-employee-report?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&status=" +
          `${calltype}` +
          "&user_id=" +
          `${employee}` +
          "&dept=" +
          `${depts}` +
          "&page=" +
          `${page}` +
          "&limit=" +
          `${limit}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const employee_Data = JSON.parse(JSON.stringify(employee_reports.data));
      if (employee_Data.status == 1) {
        setReports(employee_Data.data.calllogs);
        setEmployeeData(employee_Data.data);
        setLoading(false);
      }
    } catch (e) {
      return false;
    }
  };

  const exportEmployeeReports = async () => {
    /*----------export employee report--------*/
    try {
      const logexporthistory = await axios.get(
        APP_URL +
          "get-call-logs-report-export?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&user_id=" +
          `${employee}` +
          "&status=" +
          `${calltype}` +
          "&dept=" +
          `${depts}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const url = window.URL.createObjectURL(new Blob([logexporthistory.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "calllog-report.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      return false;
    }
  };

  const handlefromdate = (e) => {
    setFromdate(e.target.value);
  };

  const handletodate = (e) => {
    setTodate(e.target.value);
  };

  const handlecalltype = (e) => {
    setCalltype(e.target.value);
  };

  const handleemployeechange = (e) => {
    setSelectedemp(e);
    setEmployee(e.value);
  };

  const handledepartment = (e) => {
    setSelectedDept(e);
    setDepts(e.value);
    loadusers(e.value);
  };

  const headers = [
    { label: "Agent name", key: "name" },
    { label: "Agent code", key: "user_unique_code" },
    { label: "Agent no", key: "agent_contact_no" },
    { label: "Client no", key: "client_update_contact_no" },
    { label: "Call type", key: "call_type" },
    { label: "Start time", key: "start_time" },
    { label: "End time", key: "end_time" },
    { label: "Call Duration", key: "updated_call_duration" },
  ];

  let data = [];
  employeeExportData.forEach((item) => {
    data.push({
      name: item.user.name,
      user_unique_code: item.user.user_unique_code,
      agent_contact_no:
        item.data[0].agent_contact_no == null
          ? ""
          : item.data[0].agent_contact_no,
      client_update_contact_no: item.data[0].client_update_contact_no,
      call_type:
        item.data[0].status == 1
          ? "incoming"
          : item.data[0].status == 2
          ? "outgoing"
          : item.data[0].status == 3
          ? "missed"
          : null,
      start_time: item.data[0].start_time,
      end_time: item.data[0].end_time,
      updated_call_duration: item.data[0].updated_call_duration,
    });
    for (let i = 1; i < item.data.length; i++) {
      const details = item.data[i];
      data.push({
        name: "",
        user_unique_code: "",
        agent_contact_no:
          details.agent_contact_no == null ? "" : details.agent_contact_no,
        client_update_contact_no: details.client_update_contact_no,
        call_type:
          details.status == 1
            ? "incoming"
            : details.status == 2
            ? "outgoing"
            : details.status == 3
            ? "missed"
            : null,
        start_time: details.start_time,
        end_time: details.end_time,
        updated_call_duration: details.updated_call_duration,
      });
    }
  });

  const employeedata = [];
  employees.forEach((item) => {
    employeedata.push({
      value: item.id,
      label: item.name + "-" + item.user_unique_code,
    });
  });

  const departmentData = [];
  departments.forEach((item) => {
    departmentData.push({ value: item.id, label: item.department_name });
  });
  return (
    <>
      <div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar />
          <div className="content-wrapper container">
            <div className="page-content">
              <section className="row">
                <div className="col-12 col-lg-12">
                  <section className="section">
                    <div className="row" id="table-hover-row">
                      <div className="col-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <h5>
                              Employee Report (
                              {employeeData && employeeData.total_data})
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="">
                                  <table className="table table table-borderless mb-0">
                                    <thead>
                                      <tr>
                                        <th>From date</th>
                                        <th>To date</th>
                                        <th>Select Department</th>
                                        <th>Select employee</th>
                                        <th>Call Type</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <input
                                            type="date"
                                            className="form-control"
                                            onChange={(e) => handlefromdate(e)}
                                            value={fromdate}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="date"
                                            className="form-control"
                                            onChange={(e) => handletodate(e)}
                                            value={todate}
                                          />
                                        </td>
                                        <td style={{ width: "250px" }}>
                                          <Select
                                            value={selectedDept}
                                            onChange={(e) =>
                                              handledepartment(e)
                                            }
                                            options={departmentData}
                                          />
                                        </td>
                                        <td style={{ width: "250px" }}>
                                          <Select
                                            value={selectedemp}
                                            onChange={(e) =>
                                              handleemployeechange(e)
                                            }
                                            options={employeedata}
                                          />
                                        </td>

                                        <td>
                                          <select
                                            className="form-control"
                                            onChange={(e) => handlecalltype(e)}
                                          >
                                            <option value="">
                                              select call type
                                            </option>
                                            <option value="1">Incoming</option>
                                            <option value="2">Outgoing</option>
                                            <option value="3">Missed</option>
                                          </select>
                                        </td>

                                        <td>
                                          <button
                                            className="btn btn-outline-success"
                                            onClick={() => employeereport(1, 1)}
                                          >
                                            Apply
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-md-12">
                                <div className="col-sm-12">
                                  <h6>Reports</h6>
                                </div>
                                <button
                                  className="btn btn-success"
                                  onClick={exportEmployeeReports}
                                >
                                  <i className="fa fa-download"></i> Export
                                </button>

                                <div className="table-responsive pb-5">
                                  <table className="table table-bordered table-hover mb-0">
                                    {loading == true ? (
                                      <>
                                        {/* <Loader /> */}
                                        <TableSkeleton count={7}/>
                                      </>
                                    ) : (
                                      <>
                                        {reports?.map((item, index) => (
                                          <>
                                            <thead>
                                              <tr className="custom_bg">
                                                <th colSpan="6">
                                                  Employee - ({item.user.name}-
                                                  {item.user.user_unique_code})
                                                </th>
                                              </tr>
                                              <tr>
                                                <th>Agent No</th>
                                                <th>Call Type</th>
                                                <th>Client No</th>
                                                <th>Start time</th>
                                                <th>End time</th>
                                                <th>Duration (in seconds)</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {item.data.map((a, v) => (
                                                <>
                                                  <tr>
                                                    <td>
                                                      {a.agent_contact_no}
                                                    </td>
                                                    <td>
                                                      {a.status == 1 ? (
                                                        <span className="badge bg-light-primary">
                                                          Incoming
                                                        </span>
                                                      ) : a.status == 2 ? (
                                                        <span className="badge bg-light-success">
                                                          Outgoing
                                                        </span>
                                                      ) : a.status == 3 ? (
                                                        <span className="badge bg-light-danger">
                                                          Missed
                                                        </span>
                                                      ) : null}
                                                    </td>
                                                    <td>
                                                      {
                                                        a.client_update_contact_no
                                                      }
                                                    </td>
                                                    <td>{a.start_time}</td>
                                                    <td>{a.end_time}</td>
                                                    <td>
                                                      {a.updated_call_duration}
                                                    </td>
                                                  </tr>
                                                </>
                                              ))}
                                            </tbody>
                                          </>
                                        ))}
                                      </>
                                    )}
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: "right", float: "right" }}>
                              <Paginationcustom
                                data={nPages}
                                changepage={changepage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Employeereports;
