import React, { useState, useEffect } from "react";
import axios from "axios";
import { APP_URL, getdates } from "../../validation/basic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ModalTap = (props) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [updateLeadStatus, setUpdateLeadStatus] = useState({
    lead_status: props.isLeadData.leadstatus,
    lead_id: props.isLeadData.updated_lead_id,
    agent_id: props.isLeadData.updated_user_id,
    desc: "",
  });
  const { lead_status, lead_id, agent_id, desc } = updateLeadStatus;
  const handledescription = (e) => {
    setUpdateLeadStatus({ ...updateLeadStatus, desc: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      props.setAddcheckStatus(false);
      var form = new FormData();
      form.append("lead_id", lead_id);
      form.append("lead_status", lead_status);
      form.append("desc", desc);
      const delete_company_details = await axios.post(
        APP_URL + "update-lead-status",
        form,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      var js_data_delete_company = JSON.parse(
        JSON.stringify(delete_company_details.data)
      );
      if (js_data_delete_company.status == 1) {
        props.setAddcheckStatus(true);
        props.setIsModal(false);
        toast.success("lead updated successfull");
      }
    } catch (e) {
      toast.error("something went wrong");
    }
  };

  return (
    <>
      <div id="demo-modal" className="modal">
        <div className="modal__content">
          <h4>
            {props.isLeadData.leadstatus === 1
              ? "Negative"
              : props.isLeadData.leadstatus === 2
              ? "Completed"
              : ""}{" "}
            Description
          </h4>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label>Description</label>
              <textarea
                className="form-control"
                rows="3"
                onChange={(e) => handledescription(e)}
              ></textarea>
            </div>
            <div className="col-md-12">
              <button className="btn btn-primary" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>

          <button
            className="btn btn-danger btn-sm modal__close rounded-pill btn-custom"
            onClick={props.CloseModal}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      </div>
    </>
  );
};
export default ModalTap;
